/* eslint-disable @typescript-eslint/naming-convention, react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { i18n } from 'i18next'

/**
 In the current version of next-translate `t` will change before
 any page-transition-animation starts. Memoizing `t` will keep it
 around while animating.
 This also gives us problems while running jest tests (infinite re-render loops where t is used in useEffect dependencies)
 Issue: https://github.com/aralroca/next-translate/issues/447
 */
const useMemoTranslation = (key?: string): { t: TFunction; i18n: i18n } => {
    const { t: _t, i18n: _i18n } = useTranslation(key)
    const memoT = useMemo(() => _t, [_i18n.language]) // `t`/'_t' should NOT be a hook dependency here.
    return { t: memoT, i18n: _i18n }
}

export default useMemoTranslation
