import { FC } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import axios from 'axios'
import { MotionConfig } from 'framer-motion'

import Widgets from './components/03.organisms/Widgets/Widgets'
import i18n from './config/i18next-config'
import ApplicationContext from './context/ApplicationContext'
import { useGlobalsContext } from './context/GlobalsContext'
import IConfig from './structures/IConfig.interface'
import { isNullOrEmpty } from './utils/guards.utils'

import './components/00.fundament/global.module.scss'

const configureAxios = () => {
    const defaultLocale = !isNullOrEmpty(navigator.languages as Array<string>)
        ? navigator.languages[0]
        : navigator.language

    axios.defaults.headers.common = Object.assign(axios.defaults.headers.common, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': defaultLocale,
    })

    i18n.on('initialized languageChanged', () => {
        axios.defaults.headers.common = Object.assign(axios.defaults.headers.common, {
            'Accept-Language': i18n.languages[0],
        })
    })
}

interface IAppProperties {
    config: IConfig
}

const App: FC<IAppProperties> = ({ config }) => {
    const { widgetOpen } = useGlobalsContext()
    configureAxios()

    return (
        <HelmetProvider>
            <ApplicationContext config={config}>
                <MotionConfig reducedMotion='never'>
                    {
                        // While widget is open, prevent browser from zooming-in on input focus. (ios)
                        widgetOpen && (
                            <Helmet>
                                <meta
                                    name='viewport'
                                    content='width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no'
                                />
                            </Helmet>
                        )
                    }
                    <Widgets />
                </MotionConfig>
            </ApplicationContext>
        </HelmetProvider>
    )
}

export default App
