import { FC, useEffect } from 'react'
import { useFormikContext } from 'formik'

import { IFormDataValue } from '../../../config/yup/formData.config'
import { useFormValuesContext } from '../../../context/FormValuesContext'

const VoucherFormObserver: FC<Record<string, never>> = () => {
    const { values } = useFormikContext<IFormDataValue>()
    const formValues = useFormValuesContext()

    useEffect(() => {
        formValues?.setSavedFormValues(values)
    }, [formValues, formValues?.setSavedFormValues, values])

    return null
}

export default VoucherFormObserver
