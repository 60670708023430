import { FC } from 'react'

const SvgSoybeans: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 105.8 149.4'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.soybeans_svg__st0{fill:#fff}'}</style>
        <path
            className='soybeans_svg__st0'
            d='M42 21.5C41.4 10 34.4 0 34.4 0c-5 2.1-14.3 11.2-14.3 11.2-2.1 2.1-4.1 4.9-6.1 8.4-3.7 6.6-6.4 14.8-6.9 20.9-.2 2.7-.1 5.3.3 7.9.6 3.8-.2 8-2.4 11.7C2.3 64.7.7 70.2.1 76c-.6 6.8.5 13.6 3 19.3 1.6 3.5 2 7.6 1 11.1-.4 1.6-.7 3.2-.8 4.9-.5 6.2 1.1 14 4.3 20.8 1.7 3.7 3.7 6.7 5.9 8.9v.1l.1.1c.6.6 1.3 1.2 1.9 1.7 6.8 5.9 12.7 7.9 16.2 5.3 2.7-2 4.5-17.2 4.5-17.2.7-3.9 1-8 1.3-12 .1-1.3.2-2.6.3-3.8.4-4.3-.1-8.7-1.5-12.6-1.4-4-1.4-8.5-.1-12.3 1.1-3.1 1.8-6.5 2.1-10 .4-4.3.1-8.6-.8-12.6-1.1-4.7-.7-9.3 1.1-12.9 1.6-3.2 2.5-6.7 2.9-10.5v-.4c.4-5.1 1.1-12.8.7-19.5 0-.9-.1-2-.2-2.9zM105.7 76.1c-.5-5.8-2.2-11.3-4.9-15.9-2.2-3.8-3-8-2.4-11.7.4-2.6.5-5.3.3-7.9-.5-6.1-3.2-14.3-6.9-20.9-1.9-3.5-4-6.3-6.1-8.4 0 0-9.3-9.1-14.3-11.2 0 0-7 10-7.6 21.5-.1 1-.2 2-.3 3.1-.5 6.7.2 14.4.7 19.5v.4c.3 3.7 1.3 7.3 2.9 10.5 1.8 3.6 2.2 8.2 1.1 12.9-.9 4.1-1.2 8.3-.8 12.6.3 3.5 1 6.8 2.1 10 1.3 3.8 1.3 8.3-.1 12.3-1.4 3.9-1.9 8.3-1.5 12.6.1 1.2.2 2.5.3 3.8.3 3.9.6 8.1 1.3 12 0 0 1.8 15.2 4.5 17.2 3.5 2.6 9.3.5 16.2-5.3.7-.5 1.3-1.1 1.9-1.7l.1-.1v-.1c2.2-2.3 4.2-5.2 5.9-8.9 3.2-6.8 4.8-14.6 4.3-20.8-.1-1.7-.4-3.3-.8-4.9-.9-3.6-.5-7.6 1-11.1 2.6-5.8 3.7-12.7 3.1-19.5zm-35.6-38c-1.4-10 2.2-18.9 8.1-20C84 17 89.9 24.3 91.3 34.2c1.4 10-2.2 18.9-8.1 20s-11.7-6.1-13.1-16.1zm23.1 83c-1.1 9-6.1 15.7-11.1 14.9-5-.8-8.2-8.8-7-17.8s6.1-15.7 11.1-14.9 8.2 8.8 7 17.8zM86.1 96c-5.7 0-10.4-8-10.4-17.9 0-9.9 4.7-17.9 10.4-17.9s10.4 8 10.4 17.9C96.5 88 91.8 96 86.1 96z'
        />
    </svg>
)

export default SvgSoybeans
