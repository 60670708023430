import { FC } from 'react'

interface ISelectBoxOptionProperties {
    value: string
    label: string
}

const SelectBoxOption: FC<ISelectBoxOptionProperties> = ({ value, label }) => <option value={value}>{label}</option>

export default SelectBoxOption
