import { FC, ReactNode } from 'react'

import style from './formelement.module.scss'

interface IFormElementProperties {
    children: ReactNode
}

const FormElement: FC<IFormElementProperties> = ({ children }) =>
    children === undefined ? null : <div className={style.formElement}>{children}</div>
export default FormElement
