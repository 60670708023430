import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGlobalsContext } from '../../../context/GlobalsContext'
import { useVenueContext } from '../../../context/VenueContext'
import RoutePaths from '../../../structures/RoutePaths.enum'
import { isNullOrEmpty } from '../../../utils/guards.utils'
import BackButton from '../../01.atoms/BackButton/BackButton'
import CloseButton from '../../01.atoms/CloseButton/CloseButton'
import Text from '../../01.atoms/Text/Text'

import style from './widgetheader.module.scss'

const WidgetHeader: FC<Record<string, never>> = () => {
    const { data: venue } = useVenueContext()
    const { setWidgetOpen } = useGlobalsContext()
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className={style.voucher__header}>
            <div className={style['voucher__header--left']}>
                {location.pathname !== RoutePaths.VOUCHER_SELECT && location.pathname !== RoutePaths.ROOT && (
                    <BackButton
                        onClick={() => {
                            navigate(RoutePaths.VOUCHER_SELECT, {
                                replace: true,
                            })
                        }}
                    />
                )}
            </div>
            <div className={style['voucher__header--center']}>
                {!isNullOrEmpty(venue?.theming?.logo) ? (
                    <img
                        className={style.logo}
                        src={venue?.theming?.logo}
                        alt={venue?.name}
                    />
                ) : (
                    !isNullOrEmpty(venue?.name) && <Text spacing='0'>{venue?.name}</Text>
                )}
            </div>
            <div className={style['voucher__header--right']}>
                <CloseButton
                    onClick={() => {
                        setWidgetOpen(false)
                    }}
                />
            </div>
        </div>
    )
}

export default WidgetHeader
