import { FC } from 'react'

import { EVENT_CATALOGUE } from '../../../api/endpoints.api'
import useFetch from '../../../api/useFetch'
import { useEventContext } from '../../../context/EventContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import ICategoryGroup from '../../../structures/ICategoryGroup.interface'
import ActivityMonitor from '../../01.atoms/ActivityMonitor/ActivityMonitor'
import Card from '../../01.atoms/Card/Card'
import Heading from '../../01.atoms/Heading/Heading'
import Product from '../../01.atoms/Product/Product'
import Text from '../../01.atoms/Text/Text'

import style from './menu.module.scss'

const Menu: FC<Record<string, never>> = () => {
    const { data: event } = useEventContext()
    const { t } = useMemoTranslation()
    const {
        data: categoryGroups,
        loading,
        error,
    } = useFetch<Array<ICategoryGroup>>(event?.id == null ? undefined : EVENT_CATALOGUE(event.id))

    return (
        <div className={style.menuContainer}>
            {loading && <ActivityMonitor />}
            {error != null && <p>{t('general.unknownError')}</p>}
            {categoryGroups?.map((categoryGroup, index) =>
                categoryGroup.categories.map((category) => (
                    <Card
                        animationDelayMs={index * 50}
                        className={style.categoryCard}
                        key={category.id}>
                        <Heading element='h2'>{category.name}</Heading>
                        <Text>{category.description}</Text>

                        <div className={style.productsContainer}>
                            {category.products.map((product) => (
                                <Product
                                    key={product.id}
                                    product={product}
                                />
                            ))}
                        </div>
                    </Card>
                ))
            )}
        </div>
    )
}

export default Menu
