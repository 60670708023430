import { useEffect } from 'react'

import { useConfigContext } from '../context/ConfigContext'
import { useGlobalsContext } from '../context/GlobalsContext'
import CustomEventType from '../structures/CustomEventType.enum'
import { isCustomBillyEvent } from '../utils/guards.utils'

/**
 * @example
 *  useInterval(() => {
 *    // Your custom callback logic here
 *  }, 5000)
 * @param wrapper - html element where the react app was rendered in
 */
const useCustomEvents = () => {
    const { setWidgetOpen, setActiveWidget } = useGlobalsContext()
    const { wrapper } = useConfigContext()

    useEffect(() => {
        const openWidgetListener = (event) => {
            if (isCustomBillyEvent(event)) {
                setWidgetOpen(true)
                setActiveWidget(event.detail)
            }
        }

        const closeWidgetListener = (event) => {
            if (isCustomBillyEvent(event)) {
                setWidgetOpen(false)
                setActiveWidget(event.detail)
            }
        }

        wrapper?.addEventListener(CustomEventType.OPEN_WIDGET, openWidgetListener)
        wrapper?.addEventListener(CustomEventType.CLOSE_WIDGET, closeWidgetListener)

        return () => {
            setWidgetOpen(false)
            wrapper?.removeEventListener(CustomEventType.OPEN_WIDGET, openWidgetListener)
            wrapper?.removeEventListener(CustomEventType.CLOSE_WIDGET, closeWidgetListener)
        }
    }, [wrapper, setActiveWidget, setWidgetOpen])
}

export default useCustomEvents
