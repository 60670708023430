import { FC } from 'react'

const SvgTreeNuts: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 103.8 129.9'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.treeNuts_svg__st0{fill:#fff}'}</style>
        <path
            className='treeNuts_svg__st0'
            d='M64 22.4c-4 2-11.3 8.4-14.8 11.5C52.8 24.7 66.3 10.5 64.5 5c-1-3.1-7.4-6.3-14.9-4.2-3.9 1.1-5.4 12-6 21.9C18.9 24.1 0 31.9 0 41.4c0 10.5 23.2 19 51.9 19s51.9-8.5 51.9-19c0-9.5-15.2-17.6-39.8-19z'
        />
        <path
            className='treeNuts_svg__st0'
            d='M51.9 70.7c-19.1 0-35.8-5.9-45.2-10.8V81c0 29.3 45.2 49 45.2 49s45.2-19.7 45.2-49V59.9C87.7 64.8 71 70.7 51.9 70.7zm-30.8 33.2C10.9 93.7 10 80.8 10 80.8V65.9l9.7 4.2v12.1s-.9 11.1 24.6 40.3c0 0-13-8.4-23.2-18.6z'
        />
    </svg>
)

export default SvgTreeNuts
