import { FC, useMemo, useRef } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import formFieldsData, {
    extractInitialValuesFromFormData,
    extractSchemaValuesFromFormData,
    getFormValuesByGroup,
    IFormData,
    IFormDataSchema,
    IFormDataValue,
    IFormFieldData,
} from '../../../config/yup/formData.config'
import { useFormValuesContext } from '../../../context/FormValuesContext'
import { useVenueContext } from '../../../context/VenueContext'
import { useVouchersContext } from '../../../context/VouchersContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import { isNullOrEmpty } from '../../../utils/guards.utils'
import ActivityMonitor from '../../01.atoms/ActivityMonitor/ActivityMonitor'
import Button from '../../01.atoms/Button/Button'
import Currency from '../../01.atoms/Currency/Currency'
import FormBlock from '../../01.atoms/FormBlock/FormBlock'
import FormElement from '../../01.atoms/FormElement/FormElement'
import VoucherFormObserver from '../../01.atoms/FormObserver/VoucherFormObserver'

import style from './voucherform.module.scss'

interface IVoucherFormProperties {
    onSubmit: (values: IFormDataValue, setSubmitting: (value: boolean) => void) => void
}

const VoucherForm: FC<IVoucherFormProperties> = ({ onSubmit }) => {
    const { t } = useMemoTranslation()
    const { data: venue } = useVenueContext()
    const { amount, totalPrice } = useVouchersContext()

    const formData = useRef<IFormData>(formFieldsData)
    const initialValues = useRef<IFormDataValue | null>(null)
    const schema = useRef<IFormDataSchema | null>(null)
    const { savedFormValues } = useFormValuesContext()

    // Add custom fields to formData
    useMemo(() => {
        const customFields = venue?.voucher_custom_fields.reduce<IFormData>(
            (accumulatorCustomFields, currentCustomField) => {
                let validationSchema = Yup.string()
                if (currentCustomField.required) {
                    validationSchema = validationSchema.required('input.feedback.required')
                    if (currentCustomField.type !== 'select') {
                        validationSchema = validationSchema.min(1)
                    }
                }
                const formFieldData: IFormFieldData = {
                    group: 'customFields',
                    label: isNullOrEmpty(currentCustomField.label) ? undefined : currentCustomField.label,
                    placeHolder: '',
                    type: currentCustomField.type,
                    required: currentCustomField.required,
                    initialValue: currentCustomField.default_input ?? '',
                    options: currentCustomField.options ?? undefined,
                    schema: validationSchema,
                }
                return {
                    ...accumulatorCustomFields,
                    [`custom_${currentCustomField.name}`]: formFieldData,
                }
            },
            {} as unknown as IFormData
        )
        formData.current = {
            ...formFieldsData,
            ...customFields,
        }
        initialValues.current = extractInitialValuesFromFormData(formData.current)
        schema.current = extractSchemaValuesFromFormData(formData.current)
    }, [venue?.voucher_custom_fields])

    useMemo(() => {
        // overwrite initial values with credentials stored in context
        if (savedFormValues !== null) {
            initialValues.current = {
                ...initialValues.current,
                ...savedFormValues,
            }
        }
    }, [savedFormValues])

    // Determine formblocks data to render
    const userCredentialsFormData = getFormValuesByGroup(formData.current, 'userCredentials')
    const receiverCredentialsFormData = getFormValuesByGroup(formData.current, 'receiverCredentials')
    const customFieldsFormData = getFormValuesByGroup(formData.current, 'customFields')
    const paymentMethodsFormData = getFormValuesByGroup(formData.current, 'paymentMethods')
    const formBlocksData = [
        {
            title: t('voucherform.sender.title'),
            hasRequiredFields: Object.values(userCredentialsFormData).some((fieldData) => fieldData.required),
            formData: userCredentialsFormData,
        },
        {
            title: t('voucherform.receiver.title'),
            hasRequiredFields: Object.values(receiverCredentialsFormData).some((fieldData) => fieldData.required),
            formData: receiverCredentialsFormData,
        },
        ...(Object.values(customFieldsFormData).length > 0
            ? [
                  {
                      title: t('voucherform.custom.title'),
                      hasRequiredFields: Object.values(customFieldsFormData).some((fieldData) => fieldData.required),
                      formData: customFieldsFormData,
                  },
              ]
            : []),
        {
            title: t('voucherform.payment.title'),
            hasRequiredFields: Object.values(paymentMethodsFormData).some((fieldData) => fieldData.required),
            formData: paymentMethodsFormData,
        },
    ]

    // wait for venue to load because otherwise the custom fields
    // do not get injected into initialValues causing Formik to error
    return initialValues.current === null || schema.current === null ? (
        <ActivityMonitor />
    ) : (
        <Formik
            initialValues={initialValues.current}
            validationSchema={Yup.object(schema.current)}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                onSubmit(values, setSubmitting)
            }}>
            {({ errors, touched, isSubmitting }) => (
                <Form
                    className={style.form}
                    role='form'>
                    <VoucherFormObserver />

                    {formBlocksData.map((formBlockData, index) => (
                        <FormBlock
                            key={formBlockData.title}
                            title={formBlockData.title}
                            hasRequiredFields={formBlockData.hasRequiredFields}
                            disabled={isSubmitting}
                            formData={formBlockData.formData}
                            errors={errors}
                            touched={touched}
                            animationDelayMs={index * 200}
                        />
                    ))}

                    <FormElement>
                        <Button
                            className={style.submitButton}
                            disabled={isSubmitting}
                            aria-label={t('voucherform.submit')}
                            type='submit'>
                            {amount === 1
                                ? t('voucherform.submit')
                                : t('voucherform.submit.multiple', { total: amount })}
                            : <Currency>{totalPrice}</Currency>
                        </Button>
                    </FormElement>
                </Form>
            )}
        </Formik>
    )
}

export default VoucherForm
