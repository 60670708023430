import { FC } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { VOUCHER_PURCHASE_OPTIONS } from '../../../api/endpoints.api'
import useFetch from '../../../api/useFetch'
import { useVenueContext } from '../../../context/VenueContext'
import { useVouchersContext } from '../../../context/VouchersContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import IVoucher from '../../../structures/IVoucher.interface'
import RoutePaths from '../../../structures/RoutePaths.enum'
import ActivityMonitor from '../../01.atoms/ActivityMonitor/ActivityMonitor'
import Heading from '../../01.atoms/Heading/Heading'
import Text from '../../01.atoms/Text/Text'
import VoucherCard from '../../01.atoms/VoucherCard/VoucherCard'
import WidgetIntro from '../WidgetIntro/WidgetIntro'

import style from './voucherSelect.module.scss'

const VoucherSelect: FC<Record<string, never>> = () => {
    const { data: venue } = useVenueContext()
    const { setActiveVoucher } = useVouchersContext()
    const { t } = useMemoTranslation()

    const {
        data: voucherOptions,
        loading,
        error,
    } = useFetch<Array<IVoucher>>(venue?.id == null ? undefined : VOUCHER_PURCHASE_OPTIONS(venue.id))

    if (error != null) {
        return (
            <section className={style.voucherSelect}>
                <p>Something went wrong</p>
            </section>
        )
    }

    return (
        <section className={style.voucherSelect}>
            <WidgetIntro>
                <Heading align='center'>{t('widget.giftcard_select.title', { venue: venue?.name })}</Heading>
                <Text align='center'>{t('widget.giftcard_select.body', { venue: venue?.name })}</Text>
            </WidgetIntro>

            <div className={style.vouchersWrapper}>
                {loading && <ActivityMonitor />}
                {voucherOptions?.map((voucher, index) => (
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: 20,
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                delay: index / 5,
                            },
                        }}
                        className={style.voucher}
                        key={voucher.id}>
                        <Link
                            className={style.voucher__link}
                            to={RoutePaths.CREDENTIALS}
                            onClick={() => {
                                setActiveVoucher(voucher)
                            }}>
                            <VoucherCard voucher={voucher} />
                        </Link>
                    </motion.div>
                ))}
            </div>
        </section>
    )
}

export default VoucherSelect
