import { FC } from 'react'

const SvgAlcohol: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 106.2 139.7'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <path
            d='M102.9 18H92.5l13.2-14.1c.9-.9.8-2.4-.1-3.3-.9-.9-2.4-.8-3.3.1L86.2 18h-83c-1.3 0-2.5.8-3 2s-.2 2.6.7 3.5l47.4 46.7c0 .2-.1.4-.1.6v54.9l-22.4 5.2c-2.4 1.2-3.6 3.9-3 6.5.6 2.6 2.9 2.4 5.6 2.4h49.3c2.7 0 5 .2 5.6-2.4.6-2.6-.6-5.3-3-6.5l-22.4-5.2v-55c0-.2 0-.4-.1-.6l47.4-46.7c.9-.9 1.2-2.3.7-3.5-.5-1.1-1.7-1.9-3-1.9zM80 39.6h-7.7L54.4 58.7c-.5.5-1.1.7-1.7.7-.6 0-1.1-.2-1.6-.6-.9-.9-1-2.3-.1-3.3l14.9-16H26.1L11 24.4h69.1L66 39.6h6.3l14.2-15.2h8.7L80 39.6z'
            style={{
                fill: '#fff',
            }}
        />
    </svg>
)

export default SvgAlcohol
