// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .Bllk5{margin:0;padding:10px}@media screen and (min-width: 750px){#billy-widget#billy-widget#billy-widget .Bllk5{padding:20px}}#billy-widget#billy-widget#billy-widget .Bllk5 .KXL0h{min-height:60px;margin-top:0}", "",{"version":3,"sources":["webpack://./components/02.molecules/VoucherForm/voucherform.module.scss","webpack://./components/00.fundament/mixins.module.scss"],"names":[],"mappings":"AAGA,+CACI,QAAA,CACA,YAAA,CCJA,qCDEJ,+CAKQ,YAAA,CAAA,CAGJ,sDACI,eAAA,CACA,YAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n@use '../../00.fundament/mixins.module' as *;\n\n.form {\n    margin: 0;\n    padding: 10px;\n\n    @include media-m {\n        padding: 20px;\n    }\n\n    .submitButton {\n        min-height: 60px;\n        margin-top: 0;\n    }\n}\n","@mixin media-m {\n    @media screen and (min-width: 750px) {\n        @content;\n    }\n}\n\n@mixin media-l {\n    @media screen and (min-width: 1000px) {\n        @content;\n    }\n}\n\n@mixin media-xl {\n    @media screen and (min-width: 1650px) {\n        @content;\n    }\n}\n\n@mixin media-xxl {\n    @media screen and (min-width: 2000px) {\n        @content;\n    }\n}\n\n@mixin text-center {\n    text-align: center;\n}\n\n@mixin text-right {\n    text-align: right;\n}\n\n@mixin text-small {\n    font-size: 14px;\n    line-height: 20px;\n}\n\n@mixin text-bold {\n    font-weight: 600;\n    font-family: var(--font-bold);\n}\n\n$spacers: (\n    'size-0': 0,\n    'size-s': 8px,\n    'size-m': 16px,\n    'size-l': 32px,\n    'size-xl': 64px,\n    'size-xxl': 128px,\n    'size-xxxl': 256px,\n);\n\n@function spacers($spacer-name) {\n    @return map-get($spacers, $spacer-name);\n}\n\n@mixin button-reset {\n    padding: 0;\n    border: none;\n    background-color: transparent;\n    color: inherit;\n    font: inherit;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"form": "Bllk5",
	"submitButton": "KXL0h"
};
export default ___CSS_LOADER_EXPORT___;
