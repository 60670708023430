import { HTMLInputTypeAttribute } from 'react'
import * as Yup from 'yup'
import { BaseSchema } from 'yup'

import PspType from '../../structures/PSPType.enum'
import { isEnumValue } from '../../utils/guards.utils'
import { addPrefixToObject } from '../../utils/prefixTypes.utils'
import getTypedEntries from '../../utils/typedEntries.utils'

export type IAllowedCustomFieldTypes = string | number | boolean

export type IFormFieldGroups = 'userCredentials' | 'receiverCredentials' | 'paymentMethods' | 'customFields'

export interface IFormFieldData {
    group: IFormFieldGroups
    label?: string
    placeHolder?: string
    type: HTMLInputTypeAttribute
    required: boolean
    initialValue: string
    options?: Array<string>
    schema: BaseSchema
}

export type IFormFieldNames =
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'receiverFirstName'
    | 'receiverLastName'
    | 'pspType'
    | 'pspMethod'
    | keyof addPrefixToObject<IFormCustomFieldsDataValue, 'custom_'>

export interface IFormCustomFieldsDataValue {
    [key: string]: string
}

export type IFormData = Record<IFormFieldNames, IFormFieldData>
// allow all field names in IFormFieldNames or any prefixed with custom_
export type IFormDataValue = Record<IFormFieldNames, IFormFieldData['initialValue'] | string>

export type IFormDataSchema = {
    [K in keyof IFormData]: IFormFieldData['schema'] | BaseSchema
}

Yup.addMethod<Yup.StringSchema>(Yup.string, 'isPspType', function format() {
    return this.transform((value, originalValue) => (isEnumValue(value, PspType) ? value : originalValue))
})

const formFieldsData: IFormData = {
    firstName: {
        group: 'userCredentials',
        label: 'input.giftcard.purchaser.firstname.label',
        placeHolder: 'input.giftcard.purchaser.firstname.placeholder',
        type: 'text',
        required: true,
        initialValue: '',
        schema: Yup.string().max(50, 'input.feedback.input_too_long').required('input.feedback.required'),
    },
    lastName: {
        group: 'userCredentials',
        label: 'input.giftcard.purchaser.lastname.label',
        placeHolder: 'input.giftcard.purchaser.lastname.placeholder',
        type: 'text',
        required: true,
        initialValue: '',
        schema: Yup.string().max(50, 'input.feedback.input_too_long').required('input.feedback.required'),
    },
    email: {
        group: 'userCredentials',
        label: 'input.giftcard.purchaser.email.label',
        placeHolder: 'input.giftcard.purchaser.email.placeholder',
        type: 'email',
        required: true,
        initialValue: '',
        schema: Yup.string().email('input.feedback.invalid_email').required('input.feedback.required'),
    },
    receiverFirstName: {
        group: 'receiverCredentials',
        label: 'input.giftcard.receiver.firstname.label',
        placeHolder: 'input.giftcard.receiver.firstname.placeholder',
        type: 'text',
        required: true,
        initialValue: '',
        schema: Yup.string().max(50, 'input.feedback.input_too_long').required('input.feedback.required'),
    },
    receiverLastName: {
        group: 'receiverCredentials',
        label: 'input.giftcard.receiver.lastname.label',
        placeHolder: 'input.giftcard.receiver.lastname.placeholder',
        type: 'text',
        required: true,
        initialValue: '',
        schema: Yup.string().max(50, 'input.feedback.input_too_long').required('input.feedback.required'),
    },
    pspType: {
        group: 'paymentMethods',
        type: 'custom_paymentMethod',
        required: true,
        initialValue: '',
        schema: Yup.string().isPspType().required('input.feedback.payment_method_required'),
    },
    pspMethod: {
        group: 'paymentMethods',
        type: 'hidden',
        required: false,
        initialValue: '',
        schema: Yup.string().notRequired().nullable(),
    },
}

export default formFieldsData

export const getFormValuesByGroup = (data: IFormData, group: IFormFieldGroups) =>
    Object.entries(data)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_key, value]) => value.group === group)
        .reduce<IFormData>(
            (accumulatedFormData, [fieldName, formFieldData]) => ({
                ...accumulatedFormData,
                [fieldName]: formFieldData,
            }),
            {} as unknown as IFormData
        )

export const extractInitialValuesFromFormData = (data: IFormData) =>
    Object.fromEntries(
        getTypedEntries(data).map<[IFormFieldNames, IFormFieldData['initialValue']]>(([key, value]) => [
            key,
            value.initialValue,
        ])
    ) as unknown as IFormDataValue

export const extractSchemaValuesFromFormData = (data: IFormData) =>
    Object.fromEntries(
        getTypedEntries(data).map<[IFormFieldNames, IFormFieldData['schema']]>(([key, value]) => [key, value.schema])
    ) as unknown as IFormDataSchema
