// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .xK0Oc{position:relative;width:100%;min-height:50px}", "",{"version":3,"sources":["webpack://./components/01.atoms/PaymentMethodInput/paymentMethodInput.module.scss"],"names":[],"mappings":"AACA,+CACI,iBAAA,CACA,UAAA,CACA,eAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.paymentMethodInput {\n    position: relative;\n    width: 100%;\n    min-height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"paymentMethodInput": "xK0Oc"
};
export default ___CSS_LOADER_EXPORT___;
