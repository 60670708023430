// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .MQbL6{padding:2px 0 5px;color:red}", "",{"version":3,"sources":["webpack://./components/01.atoms/InputFeedback/InputFeedback.module.scss"],"names":[],"mappings":"AACA,+CACI,iBAAA,CACA,SAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.errorMessage {\n    padding: 2px 0 5px;\n    color: #ff0000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"errorMessage": "MQbL6"
};
export default ___CSS_LOADER_EXPORT___;
