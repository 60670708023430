import {
    SvgAlcohol,
    SvgCelery,
    SvgCrustaceans,
    SvgEgg,
    SvgFish,
    SvgGluten,
    SvgLupin,
    SvgMilk,
    SvgMolluscs,
    SvgMustard,
    SvgPeanuts,
    SvgSesame,
    SvgSoybeans,
    SvgSulphurDioxide,
    SvgTreeNuts,
} from './icons/index'

interface IAllergen {
    id: number
    icon: JSX.Element
    color: string
    name: string
    display_name: string
}

const ALLERGENS: Array<IAllergen> = [
    {
        id: 1,
        icon: <SvgEgg />,
        color: '#ffa32f',
        name: 'eggs',
        display_name: 'allergens.eggs',
    },
    {
        id: 2,
        icon: <SvgSoybeans />,
        color: '#81913c',
        name: 'soy',
        display_name: 'allergens.soy',
    },
    {
        id: 3,
        icon: <SvgMilk />,
        color: '#78bab5',
        name: 'milk',
        display_name: 'allergens.milk',
    },
    {
        id: 4,
        icon: <SvgCrustaceans />,
        color: '#df8266',
        name: 'crustaceans', // Schaaldieren
        display_name: 'allergens.crustaceans',
    },
    {
        id: 5,
        icon: <SvgMustard />,
        color: '#b48146',
        name: 'mustard',
        display_name: 'allergens.mustard',
    },
    {
        id: 6,
        icon: <SvgFish />,
        color: '#0c8389',
        name: 'fish',
        display_name: 'allergens.fish',
    },
    {
        id: 7,
        icon: <SvgMolluscs />,
        color: '#38aeba',
        name: 'molluscs', // Weekdieren
        display_name: 'allergens.molluscs',
    },
    {
        id: 8,
        icon: <SvgGluten />,
        color: '#b99f00',
        name: 'gluten',
        display_name: 'allergens.gluten',
    },
    {
        id: 9,
        icon: <SvgSesame />,
        color: '#edb04d',
        name: 'sesame',
        display_name: 'allergens.sesame',
    },
    {
        id: 10,
        icon: <SvgSulphurDioxide />,
        color: '#1e8e7e',
        name: 'sulphites',
        display_name: 'allergens.sulphites',
    },
    {
        id: 11,
        icon: <SvgPeanuts />,
        color: '#d1a171',
        name: 'peanuts',
        display_name: 'allergens.peanuts',
    },
    {
        id: 12,
        icon: <SvgTreeNuts />,
        color: '#844e36',
        name: 'nuts',
        display_name: 'allergens.nuts',
    },
    {
        id: 13,
        icon: <SvgAlcohol />,
        color: '#692d9e',
        name: 'alcohol',
        display_name: 'allergens.alcohol',
    },
    {
        id: 14,
        icon: <SvgLupin />,
        color: '#9833d3',
        name: 'lupin',
        display_name: 'allergens.lupin',
    },
    {
        id: 15,
        icon: <SvgCelery />,
        color: '#b5c652',
        name: 'celery',
        display_name: 'allergens.celery',
    },
]

export default ALLERGENS
