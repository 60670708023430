import IVoucherPurchase from '../structures/IVoucherPurchase.interface'
import IVoucherPurchasePayment from '../structures/IVoucherPurchasePayment.interface'
import IVoucherPurchasePaymentBody from '../structures/IVoucherPurchasePaymentBody.interface'
import IVoucherPurchaseBody from '../structures/IVoucherPurchaseRequest.interface'

import { VOUCHER_PAYMENT_CREATE, VOUCHER_PURCHASE_CREATE } from './endpoints.api'
import { POST } from './methods.api'

export const createVoucherPurchase = async (venueId: string, body: IVoucherPurchaseBody) =>
    POST<IVoucherPurchase, IVoucherPurchaseBody>(VOUCHER_PURCHASE_CREATE(venueId), body)

export const createVoucherPurchasePayment = async (voucherPurchaseUuid: string, body: IVoucherPurchasePaymentBody) =>
    POST<IVoucherPurchasePayment, IVoucherPurchasePaymentBody>(VOUCHER_PAYMENT_CREATE(voucherPurchaseUuid), body)
