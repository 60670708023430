import { FC } from 'react'

const SvgMilk: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 64.5 157.4'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.milk_svg__st0{fill:#fff}'}</style>
        <path
            className='milk_svg__st0'
            d='M19.5 11.2H45c1.8 0 3.2-1.6 3.2-3.6v-4c0-2-1.4-3.6-3.2-3.6H19.5c-1.8 0-3.2 1.6-3.2 3.6v4.1c0 1.9 1.4 3.5 3.2 3.5zM62.6 47.6 46.4 15.4H18.1L1.9 47.6C.6 50 0 52.7 0 55.5v84c0 7.5 4.5 13.9 11 15.6.1 0 .1 0 .2.1 4.8 1.5 11.9 2.2 21 2.2s16.2-.8 21-2.2c.1 0 .2-.1.2-.1 6.5-1.7 11-8.1 11-15.6v-84c.1-2.8-.5-5.5-1.8-7.9zm-45 93.8v10.8S5.5 150 5.5 141.4V66.8h12.1v74.6zm41.2-79.7h-53v-6.3c0-1.6.4-3.3 1.1-4.7l14.6-28.9H43l14.6 28.9c.7 1.4 1.1 3.1 1.1 4.7v6.3z'
        />
    </svg>
)

export default SvgMilk
