import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import style from './card.module.scss'

interface ICardProperties {
    children: ReactNode
    className?: string
    animationDelayMs?: number
}

const Card: FC<ICardProperties> = ({ children, className, animationDelayMs = 0 }) => (
    <motion.div
        initial={{
            opacity: 0,
            y: 20,
        }}
        animate={{
            opacity: 1,
            y: 0,
            transition: {
                delay: animationDelayMs / 1000,
            },
        }}
        className={classNames(style.card, className)}>
        {children}
    </motion.div>
)

export default Card
