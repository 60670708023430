import { FC } from 'react'
import classNames from 'classnames'
import { FieldProps } from 'formik'
import sanitizeHtml from 'sanitize-html'

import style from './checkbox.module.scss'

interface ICheckboxProperties {
    id: string
    label?: string
    className?: string
    disabled?: boolean
}

const Checkbox: FC<FieldProps & ICheckboxProperties> = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched },
    id,
    label,
    className,
    disabled = false,
}) => {
    const classes = classNames(
        {
            'has-error': errors[name] !== undefined && touched[name] === true,
            'u-disabled': disabled,
        },
        className,
        style['c-checkbox--default']
    )
    return (
        <div className={classes}>
            <input
                name={name}
                id={id}
                type='checkbox'
                value={value ?? ''}
                checked={value}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                className={classNames('checkbox')}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
                htmlFor={id}
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }}
            />
        </div>
    )
}

export default Checkbox
