import { FC } from 'react'

const SvgBillyIcon: FC<Record<string, never>> = () => (
    <svg
        data-name='Billy'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 355.3 355.18'>
        <path
            d='M727.65 308.78c-.35 29-1.1 58-6.93 86.53-9.43 46.13-40.73 76.7-87.16 85-35.85 6.39-72.06 7.11-108.35 5.79-20.57-.75-41.08-2.12-61.28-6.29-46.31-9.57-76.8-40.52-85.18-87-6.47-35.83-7.16-72-5.85-108.32.75-20.71 2.14-41.36 6.34-61.7 9.53-46.11 40.73-76.68 87.19-85 35.84-6.41 72.06-7.13 108.35-5.79 20.56.76 41.08 2.1 61.28 6.28 46.46 9.61 76.88 40.79 85.27 87.42 1.43 8 2.64 16.05 3.45 24.13a576.75 576.75 0 0 1 2.87 58.95Z'
            transform='translate(-372.35 -131.47)'
            style={{
                fill: '#ea5257',
            }}
        />
        <path
            d='M475.32 308.19c0-27.5 1.54-45.25 4.37-74.62 1-10.52 6.37-15.12 15.24-15.3 26.16-.51 38.54-.25 64.71.26 18 .35 35.62 3.26 51.66 12.21 14.79 8.27 23.2 20.55 22.84 37.92-.29 14.33-6.89 25.1-19.15 32.47a20.93 20.93 0 0 0-3 2.73 19.78 19.78 0 0 0 3.34 2.5c7.73 3.63 14.94 7.95 20.5 14.6 8.86 10.61 9.71 22.85 6.69 35.62-4.43 18.78-17.16 29.88-34.6 36.42a91.67 91.67 0 0 1-31.6 5.6c-32.23.25-50.65.13-82.89.12-9.65 0-14.25-6.15-15.25-15.49-2.96-27.4-2.86-47.29-2.86-75.04Zm79.91 18.46c-2.66 0-5.32.05-8 0-2.21-.05-3.53.93-3.55 3.14-.06 5.31-.16 10.64.18 15.94.07 1.05 2 2.75 3.2 2.82a129.88 129.88 0 0 0 14.5 0c7.14-.35 11.75-5.13 11.64-11.71-.1-5.86-4.74-9.87-11.82-10.15-2.03-.1-4.09-.04-6.15-.04Zm-11.63-47.81v7.63c0 2.32 1.3 3.69 3.6 3.68a115 115 0 0 0 13.4-.41c6.18-.73 9.76-5.15 9.64-11.1A11 11 0 0 0 559.86 268c-4.22-.32-8.47-.14-12.71-.14-2.43 0-3.47 1.48-3.54 3.71-.07 2.43-.01 4.85-.01 7.27Z'
            transform='translate(-372.35 -131.47)'
            style={{
                fill: '#fff',
            }}
        />
    </svg>
)

export default SvgBillyIcon
