import { FC, ReactNode } from 'react'

import { VENUE_BY_ID } from '../api/endpoints.api'
import useFetch from '../api/useFetch'
import IFetchResult from '../structures/IFetchResult.interface'
import IVenue from '../structures/IVenue.interface'
import createGenericContext from '../utils/genericContext.utils'

import { useConfigContext } from './ConfigContext'

const [useVenueContext, VenueContextProvider] = createGenericContext<IFetchResult<IVenue>>()

interface IVenueProviderProperties {
    children: ReactNode
}

const VenueProvider: FC<IVenueProviderProperties> = ({ children }) => {
    const config = useConfigContext()

    const venueFetchResult = useFetch<IVenue>(VENUE_BY_ID(config.venue))

    return <VenueContextProvider value={venueFetchResult}>{children}</VenueContextProvider>
}

export { useVenueContext, VenueProvider }
