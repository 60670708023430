import { FC, MouseEventHandler } from 'react'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'

import useMemoTranslation from '../../../hooks/useMemoTranslation'

import style from './closebutton.module.scss'

interface ICloseButtonProperties {
    onClick: MouseEventHandler<HTMLButtonElement>
}

const CloseButton: FC<ICloseButtonProperties> = ({ onClick }) => {
    const { t } = useMemoTranslation()
    return (
        <button
            type='button'
            aria-label={t('general.close')}
            className={style.close}
            onClick={onClick}>
            <FaTimes />
        </button>
    )
}

export default CloseButton
