// eslint-disable unicorn/filename-case
import axios, { AxiosResponse } from 'axios'

import IApiResourceWrapper, { isApiResourceWrapped } from '../structures/IApiResourceWrapper.interface'

// @improvement - implement AbortController https://axios-http.com/docs/cancellation
export const GET = async <ResponseType>(endpoint: string) =>
    new Promise((resolve: (value: ResponseType) => void, reject: (error: unknown) => void) => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                const { data: responseData } = await axios.get<ResponseType | IApiResourceWrapper<ResponseType>>(
                    endpoint
                )
                resolve(isApiResourceWrapped<ResponseType>(responseData) ? responseData.data : responseData)
            } catch (error: unknown) {
                reject(error)
            }
        })()
    })

export const POST = async <ResponseType, BodyType>(endpoint: string, data: BodyType) =>
    new Promise((resolve: (value: ResponseType) => void, reject: (error: unknown) => void) => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                const { data: responseData } = await axios.post<
                    ResponseType | IApiResourceWrapper<ResponseType>,
                    AxiosResponse<ResponseType | IApiResourceWrapper<ResponseType>>,
                    BodyType
                >(endpoint, data)
                resolve(isApiResourceWrapped<ResponseType>(responseData) ? responseData.data : responseData)
            } catch (error: unknown) {
                reject(error)
            }
        })()
    })
