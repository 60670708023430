import { FC } from 'react'

import { VOUCHER_PURCHASE_PSPS } from '../../../api/endpoints.api'
import useFetch from '../../../api/useFetch'
import { useVenueContext } from '../../../context/VenueContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import IVenuePsp from '../../../structures/IVenuePsp.interface'
import PaymentOptions from '../../02.molecules/PaymentOptions/PaymentOptions'
import ActivityMonitor from '../ActivityMonitor/ActivityMonitor'

import style from './paymentMethodInput.module.scss'

interface IPaymentMethodInputProperties {
    disabled?: boolean
}

const PaymentMethodInput: FC<IPaymentMethodInputProperties> = ({ disabled }) => {
    const { t } = useMemoTranslation()
    const { data: venue } = useVenueContext()

    const {
        data: venuePsps,
        loading: isLoadingPaymentOptions,
        error: fetchError,
    } = useFetch<Array<IVenuePsp>>(venue?.id == null ? undefined : VOUCHER_PURCHASE_PSPS(venue.id))

    if (fetchError !== null) {
        return <p>{t('general.unknownError')}</p>
    }

    return (
        <div className={style.paymentMethodInput}>
            {isLoadingPaymentOptions ? (
                <ActivityMonitor />
            ) : venuePsps == null ? null : (
                <PaymentOptions
                    psps={venuePsps}
                    disabled={disabled}
                />
            )}
        </div>
    )
}

export default PaymentMethodInput
