import { FC } from 'react'

const SvgSulphurDioxide: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 122.1 118'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.sulphurDioxide_svg__st0{fill:#fff}'}</style>
        <path
            className='sulphurDioxide_svg__st0'
            d='M65.2 79.2c-4.8 0-7.6 4.5-7.6 10.6s2.9 10.4 7.6 10.4c4.8 0 7.5-4.5 7.5-10.6 0-5.6-2.7-10.4-7.5-10.4z'
        />
        <path
            className='sulphurDioxide_svg__st0'
            d='M120 95.2 85.6 35.5v-5.6c4.4-2.7 7.3-7.5 7.3-13v-1.8C92.9 6.8 86.1 0 77.7 0H44.4C36 0 29.2 6.8 29.2 15.2V17c0 5.5 2.9 10.3 7.3 13v5.6L2 95.2c-2.7 4.7-2.7 10.5 0 15.2 2.7 4.7 7.7 7.6 13.2 7.6h91.7c5.4 0 10.4-2.9 13.2-7.6 2.6-4.7 2.6-10.5-.1-15.2zm-76-76c-1.1-.2-1.9-1.2-1.9-2.3v-1.8c0-1.3 1-2.3 2.3-2.3h33.3c1.3 0 2.3 1 2.3 2.3v1.8c0 1.1-.8 2.1-1.9 2.3-3.1.5-5.4 3.2-5.4 6.3v11.6c0 1.1.3 2.2.9 3.2L83 56.7l-44.2.6 9.7-16.8c.6-1 .9-2.1.9-3.2V25.6c0-3.2-2.3-5.8-5.4-6.4zm-9.8 86.6c-3.6 0-7.1-.9-8.8-1.9l1.4-5.8c1.9 1 4.8 1.9 7.8 1.9 3.2 0 5-1.3 5-3.4 0-1.9-1.5-3.1-5.2-4.4-5.2-1.8-8.6-4.7-8.6-9.2 0-5.3 4.4-9.4 11.8-9.4 3.5 0 6.1.7 8 1.6L44 80.8c-1.3-.6-3.5-1.5-6.5-1.5-3.1 0-4.5 1.4-4.5 3 0 2 1.8 2.9 5.8 4.4 5.5 2 8.1 4.9 8.1 9.3-.1 5.3-4.1 9.8-12.7 9.8zm30.6 0c-9.3 0-14.7-7-14.7-15.9 0-9.4 6-16.4 15.2-16.4 9.6 0 14.8 7.2 14.8 15.8.1 10.3-6.1 16.5-15.3 16.5zm31.9-.5H82.4v-2.6l2.8-2.6c3.5-3.1 5.4-4.7 5.4-6.7 0-1.3-1-2.2-2.7-2.2-1.5 0-3 .6-4 1.3L82.6 89c1.5-1.1 3.9-2 6.7-2 4.6 0 7.1 2.5 7.1 5.6 0 3.3-2.5 5.4-5.3 7.7l-1.1.9v.1h6.8v4z'
        />
    </svg>
)

export default SvgSulphurDioxide
