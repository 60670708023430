import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdMailOutline } from '@react-icons/all-files/md/MdMailOutline'

import { VOUCHER_PURCHASE_BY_ID } from '../../../api/endpoints.api'
import { GET } from '../../../api/methods.api'
import { useGlobalsContext } from '../../../context/GlobalsContext'
import { useVouchersContext } from '../../../context/VouchersContext'
import useInterval from '../../../hooks/useInterval'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import IVoucherPurchase from '../../../structures/IVoucherPurchase.interface'
import RoutePaths from '../../../structures/RoutePaths.enum'
import handleError from '../../../utils/errors.utils'
import ActivityMonitor from '../../01.atoms/ActivityMonitor/ActivityMonitor'
import Button from '../../01.atoms/Button/Button'
import Card from '../../01.atoms/Card/Card'
import Heading from '../../01.atoms/Heading/Heading'
import Text from '../../01.atoms/Text/Text'

import style from './purchasestatus.module.scss'

const PurchaseStatus: FC<Record<string, never>> = () => {
    const pollInterval = 8000

    const { t } = useMemoTranslation()
    const navigate = useNavigate()
    const { setActiveWidget, setWidgetOpen } = useGlobalsContext()
    const { voucherPurchaseId, voucherPurchase, setVoucherPurchase, voucherPurchasePaymentUrl } = useVouchersContext()

    const [isPolling, setIsPolling] = useState(true)

    const pollForStatus = useCallback(async () => {
        if (voucherPurchaseId !== null) {
            try {
                const fetchedVoucherPurchase = await GET<IVoucherPurchase>(VOUCHER_PURCHASE_BY_ID(voucherPurchaseId))
                setVoucherPurchase(fetchedVoucherPurchase)
            } catch (error) {
                handleError(error, console.error, t)
            }
        }
    }, [voucherPurchaseId, setVoucherPurchase, t])

    useInterval(pollForStatus, isPolling ? pollInterval : null, true)

    useEffect(() => {
        if (voucherPurchaseId === undefined || voucherPurchase?.payment === null) {
            navigate(RoutePaths.CREDENTIALS, {
                replace: true,
            })
        }
    }, [navigate, voucherPurchaseId, voucherPurchase])

    useEffect(() => {
        if (voucherPurchase?.is_paid === true) {
            setIsPolling(false)
        }
    }, [voucherPurchase])

    const VoucherPaid = (
        <>
            <div className={style.emailIcon__container}>
                <MdMailOutline />
            </div>
            <div>
                <Heading
                    element='h2'
                    align='center'>
                    {t('purchase_status.payment.complete.title')}
                </Heading>
                <Text align='center'>{t('purchase_status.payment.complete.body')}</Text>

                <Button
                    className={style.close}
                    onClick={() => {
                        setWidgetOpen(false)
                        setActiveWidget(null)
                    }}>
                    {t('purchase_status.payment.button.close.cta')}
                </Button>
            </div>
        </>
    )

    const VoucherUnPaid = (
        <>
            <div>
                <ActivityMonitor
                    className={style.unpaidActivityMonitor}
                    isStatic
                />
            </div>
            <div>
                <Heading
                    element='h2'
                    align='center'>
                    {t('purchase_status.payment.pending.title')}
                </Heading>
                <Text align='center'>{t('purchase_status.payment.pending.body')}</Text>

                {voucherPurchasePaymentUrl !== null && (
                    <Button
                        type='link'
                        styleType='link'
                        target='_blank'
                        external
                        to={voucherPurchasePaymentUrl}>
                        {t('purchase_status.button.go_to_payment.cta')}
                    </Button>
                )}
            </div>
        </>
    )

    return (
        <section>
            <div className={style.purchaseStatus}>
                <Card className={style.purchaseStatus__card}>
                    <div className={style.card__inner}>
                        {voucherPurchase?.is_paid === true ? VoucherPaid : VoucherUnPaid}
                    </div>
                </Card>
            </div>
        </section>
    )
}

export default PurchaseStatus
