import { useEffect } from 'react'

import { useConfigContext } from '../context/ConfigContext'
import { useVenueContext } from '../context/VenueContext'
import { applyTheme } from '../utils/config.utils'

/**
 * @example
 *  useInterval(() => {
 *    // Your custom callback logic here
 *  }, 5000)
 * @param wrapper - html element where the react app was rendered in
 */
const useTheme = () => {
    const config = useConfigContext()
    const { data: venue } = useVenueContext()

    useEffect(() => {
        if (venue?.theming !== undefined && venue?.theming?.color !== undefined && config.wrapper !== undefined) {
            applyTheme({
                '--theme-color': `#${venue.theming.color}`,
            })
        }
    }, [venue?.theming, venue?.theming?.color, config.wrapper])
}

export default useTheme
