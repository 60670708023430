import { FC, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { createVoucherPurchase, createVoucherPurchasePayment } from '../../../api/actions.api'
import { IFormDataValue } from '../../../config/yup/formData.config'
import { useVenueContext } from '../../../context/VenueContext'
import { useVouchersContext } from '../../../context/VouchersContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import IBillyError from '../../../structures/IBillyError.interface'
import IVoucherPurchaseRequest from '../../../structures/IVoucherPurchaseRequest.interface'
import PspType from '../../../structures/PSPType.enum'
import RoutePaths from '../../../structures/RoutePaths.enum'
import handleError from '../../../utils/errors.utils'
import { isEnumValue, isNotNullOrUndefined, isNullOrEmpty } from '../../../utils/guards.utils'
import VoucherCard from '../../01.atoms/VoucherCard/VoucherCard'
import VoucherQuantitySelector from '../../01.atoms/VoucherQuantitySelector/VoucherQuantitySelector'
import VoucherForm from '../../02.molecules/VoucherForm/VoucherForm'

import style from './usercredentials.module.scss'

const UserCredentials: FC<Record<string, never>> = () => {
    const navigate = useNavigate()
    const { t } = useMemoTranslation()

    const { data: venue } = useVenueContext()
    const { activeVoucher, setVoucherPurchaseId, setVoucherPurchasePaymentUrl, amount, maxVouchers } =
        useVouchersContext()

    const [onVoucherCreateError, setOnVoucherCreateError] = useState<IBillyError | null>(null)

    const onSubmit = async (values: IFormDataValue, setSubmitting: (value: boolean) => void) => {
        setSubmitting(true)
        if (activeVoucher === null) {
            setOnVoucherCreateError({
                message: t('voucherForm.errors.noVoucherFound'),
            })
            return
        }

        const customFields: IVoucherPurchaseRequest['custom_fields'] =
            {} as unknown as IVoucherPurchaseRequest['custom_fields']
        if (venue?.voucher_custom_fields !== undefined) {
            for (const field of venue.voucher_custom_fields) {
                const customFieldKey = `custom_${field.name}`
                const fieldValue = values[customFieldKey]
                if (fieldValue !== undefined) {
                    customFields[field.id] = fieldValue
                }
            }
        }

        const valuesFormatted: IVoucherPurchaseRequest = {
            voucher_purchase_option: activeVoucher.id,
            purchaser_name: `${values.firstName} ${values.lastName}`,
            purchaser_email: `${values.email}`,
            receiver_name: `${values.receiverFirstName} ${values.receiverLastName}`,
            custom_fields: customFields,
            quantity: amount,
        }

        try {
            if (venue === null) {
                setOnVoucherCreateError({
                    message: t('voucherForm.errors.noVenueFound'),
                })
                return
            }

            const pspType = values.pspType
            const pspMethod = values.pspMethod
            if (!isEnumValue(pspType, PspType)) {
                setOnVoucherCreateError({
                    message: t('voucherForm.errors.noPspFound'),
                })
                return
            }

            // create voucher purchase
            const voucherPurchase = await createVoucherPurchase(venue.id, valuesFormatted)

            // create a voucher purchase payment
            const voucherPurchasePayment = await createVoucherPurchasePayment(voucherPurchase.uuid, {
                psp_type: pspType,
                method: pspMethod ?? pspType,
            })

            if (
                !isNullOrEmpty(voucherPurchasePayment.action?.url) &&
                isNotNullOrUndefined(voucherPurchasePayment.action?.url)
            ) {
                if (isMobile) {
                    window.location.assign(voucherPurchasePayment.action.url)
                } else {
                    window.open(voucherPurchasePayment.action.url, '_blank')?.focus()
                }

                setVoucherPurchaseId(voucherPurchase.uuid)
                setVoucherPurchasePaymentUrl(voucherPurchasePayment.action.url)
                navigate(RoutePaths.PURCHASE_STATUS, {
                    replace: true,
                })
            } else {
                setOnVoucherCreateError({
                    message: t('voucherForm.errors.noPaymentUrlFound'),
                })
            }
            setSubmitting(false)
        } catch (error: unknown) {
            handleError(error, setOnVoucherCreateError, t)
            setSubmitting(false)
        }
    }

    return (
        <section>
            <div className={style.chosenVoucher}>
                <div className={style.chosenVoucher__inner}>
                    {activeVoucher !== null && (
                        <div>
                            <VoucherCard
                                clickable={false}
                                voucher={activeVoucher}
                            />
                        </div>
                    )}
                </div>
            </div>

            {!isNullOrEmpty(onVoucherCreateError?.message) && <p>{onVoucherCreateError?.message}</p>}

            {maxVouchers === 1 ? null : <VoucherQuantitySelector />}
            <VoucherForm onSubmit={onSubmit} />
        </section>
    )
}

export default UserCredentials
