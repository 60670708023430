import { FC } from 'react'

const SvgGluten: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 78 145'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.gluten_svg__st0{fill:#fff}'}</style>
        <path
            className='gluten_svg__st0'
            d='M41.9 75.2c0 8.4 19.7-1.7 25.1-7.4 12.4-13 11-37.6 11-37.6s0 13.9-18.3 20.2c-18.4 6.4-17.8 24.8-17.8 24.8zM59.7 84C41.4 90.3 42 108.8 42 108.8c0 8.4 19.7-1.7 25.1-7.4 12.4-13 11-37.6 11-37.6S78 77.6 59.7 84zM59.7 117.1C41.4 123.4 42 141.9 42 141.9c0 8.4 19.7-1.7 25.1-7.4 12.4-13 11-37.6 11-37.6s-.1 13.8-18.4 20.2zM18.4 50.5C.1 44.1.1 30.2.1 30.2s-1.4 24.6 11 37.6c5.4 5.6 25.1 15.8 25.1 7.4-.1 0 .5-18.4-17.8-24.7zM18.4 84C.1 77.6.1 63.7.1 63.7s-1.4 24.6 11 37.6c5.4 5.6 25.1 15.8 25.1 7.4-.1.1.5-18.4-17.8-24.7zM18.4 117.1C.1 110.7.1 96.8.1 96.8s-1.4 24.6 11 37.6c5.4 5.6 25.1 15.8 25.1 7.4-.1 0 .5-18.4-17.8-24.7zM38.8 0s-13 22.2-13 31.2 13 20.6 13 20.6 13-11.7 13-20.6c0-9-13-31.2-13-31.2z'
        />
    </svg>
)

export default SvgGluten
