import { useEffect, useState } from 'react'

import useMemoTranslation from '../hooks/useMemoTranslation'
import IBillyError from '../structures/IBillyError.interface'
import IFetchResult, { defaultFetchResult } from '../structures/IFetchResult.interface'
import handleError from '../utils/errors.utils'

import { GET } from './methods.api'

const useFetch = <ResponseType>(endpoint?: string): IFetchResult<ResponseType> => {
    const [data, setData] = useState<ResponseType | null>(defaultFetchResult.data)
    const [error, setError] = useState<IBillyError | null>(defaultFetchResult.error)
    const [loading, setLoading] = useState<boolean>(defaultFetchResult.loading)
    const { t } = useMemoTranslation()

    useEffect(() => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                setLoading(true)
                if (endpoint === undefined) {
                    return
                }
                const responseData = await GET<ResponseType>(endpoint)
                setData(responseData)
                setError(null)
            } catch (error_: unknown) {
                handleError(error_, setError, t)
            } finally {
                setLoading(false)
            }
        })()
    }, [endpoint, t])

    return { data, error, loading }
}

export default useFetch
