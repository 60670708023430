import ReactDOM from 'react-dom/client'

import 'regenerator-runtime/runtime'

import CustomEventType from './structures/CustomEventType.enum'
import WidgetType from './structures/WidgetType.enum'
import { defaultConfig } from './utils/config.utils'
import App from './App'

const initWidget = () => {
    const appContainer = document.createElement('div')
    appContainer.id = 'billy-widget'
    document.body.append(appContainer)
    defaultConfig.wrapper = appContainer

    // get the id from the embed script
    const instanceId = document.currentScript?.id
    if (instanceId == null) {
        return
    }

    // preloader for our widget, iterate over all methods that were called up until now
    for (let index = 0; index < window[instanceId].q.length; index += 1) {
        const item = window[instanceId].q[index]
        const methodName = item[0]

        // only init is allowed
        if (index === 0 && methodName !== 'init') {
            throw new Error(`Failed to start Billy-Widget [${instanceId}]. 'init' must be called before other methods.`)
        }

        // render the app if init was called
        if (methodName === 'init') {
            const root = ReactDOM.createRoot(appContainer)
            root.render(<App config={item[1]} />)
        }
    }

    // once finished processing all async calls, convert out instance into sync method handler
    window[instanceId] = (method) => {
        switch (method) {
            case 'open-menu': {
                appContainer.dispatchEvent(
                    new CustomEvent<WidgetType>(CustomEventType.OPEN_WIDGET, {
                        detail: WidgetType.WIDGET_MENU,
                    })
                )
                break
            }
            case 'open-giftcard': {
                appContainer.dispatchEvent(
                    new CustomEvent<WidgetType>(CustomEventType.OPEN_WIDGET, {
                        detail: WidgetType.WIDGET_VOUCHERS,
                    })
                )
                break
            }
            case 'close': {
                appContainer.dispatchEvent(new CustomEvent(CustomEventType.CLOSE_WIDGET))
                break
            }
            default: {
                console.error(`Unsupported method: ${method}`)
            }
        }
    }

    // Bind our open event to a classname so users can bind the event to anything in the DOM
    // @Improvement Create one for close too?
    const billyWidgetTriggers = document.getElementsByClassName('billy-widget-trigger')
    if (billyWidgetTriggers.length > 0) {
        // eslint-disable-next-line unicorn/prefer-spread
        for (const trigger of Array.from(billyWidgetTriggers)) {
            trigger.addEventListener('click', (event) =>
                appContainer.dispatchEvent(
                    new CustomEvent(CustomEventType.OPEN_WIDGET, {
                        detail: (event.target as Element).classList.contains('billy-widget-giftcard')
                            ? WidgetType.WIDGET_VOUCHERS
                            : WidgetType.WIDGET_MENU,
                    })
                )
            )
        }
    }
}

initWidget()
