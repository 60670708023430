import { FC } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { useConfigContext } from '../../../context/ConfigContext'
import { useEventContext } from '../../../context/EventContext'
import { useGlobalsContext } from '../../../context/GlobalsContext'
import { useVouchersContext } from '../../../context/VouchersContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import WidgetType from '../../../structures/WidgetType.enum'
import SvgBillyIcon from '../../01.atoms/BillyIcon/SvgBillyIcon'
import WidgetTriggerButton from '../../01.atoms/WidgetTriggerButton/WidgetTriggerButton'

import style from './widgettriggers.module.scss'

const WidgetTriggers: FC<Record<string, never>> = () => {
    const config = useConfigContext()
    const { t } = useMemoTranslation()
    const { data: event } = useEventContext()
    const { giftCardsEnabled } = useVouchersContext()
    const { setActiveWidget } = useGlobalsContext()

    return (
        <motion.div
            className={classNames(
                style.widgetTrigger__wrapper,
                style[`position__horizontal--${config.buttonsPositionHorizontal}`],
                style[`position__vertical--${config.buttonsPositionVertical}`]
            )}
            initial={{
                y: 10,
                opacity: 0,
            }}
            animate={{
                y: 0,
                opacity: 1,
            }}>
            <button
                type='button'
                aria-label='widget'
                onClick={() => {
                    setActiveWidget(WidgetType.WIDGET_BILLY)
                }}
                className={style.branding}>
                <SvgBillyIcon />
            </button>
            {event != null && (
                <WidgetTriggerButton
                    onClick={() => {
                        setActiveWidget(WidgetType.WIDGET_MENU)
                    }}
                    label={t('widget_trigger.menu.cta')}
                />
            )}
            {giftCardsEnabled && (
                <WidgetTriggerButton
                    onClick={() => {
                        setActiveWidget(WidgetType.WIDGET_VOUCHERS)
                    }}
                    label={t('widget_trigger.voucher.cta')}
                />
            )}
            {config.takeout !== undefined && (
                <WidgetTriggerButton
                    onClick={() => {
                        setActiveWidget(WidgetType.WIDGET_TAKEOUT)
                    }}
                    label={t('widget_trigger.takeout.cta')}
                />
            )}
            {config.delivery !== undefined && (
                <WidgetTriggerButton
                    onClick={() => {
                        setActiveWidget(WidgetType.WIDGET_DELIVERY)
                    }}
                    label={t('widget_trigger.delivery.cta')}
                />
            )}
        </motion.div>
    )
}

export default WidgetTriggers
