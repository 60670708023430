import { FC, ReactNode } from 'react'

import style from './inputlabel.module.scss'

interface IInputLabelProperties {
    htmlFor: string
    children: ReactNode
}

const InputLabel: FC<IInputLabelProperties> = ({ htmlFor, children }) => (
    <label
        className={style.label}
        htmlFor={htmlFor}>
        {children}
    </label>
)

export default InputLabel
