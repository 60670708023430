import { FC } from 'react'

const SvgFish: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 135.8 123.2'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.fish_svg__st0{fill:#fff}'}</style>
        <path
            className='fish_svg__st0'
            d='M128 66c4.6-1.9 9.2-5.7 7.3-12.7-3.4-13.1-.1-23-.1-23s-17.3 8.2-20.9 20.2c-.4 1.5-.6 3.8-.6 6.2-9.3-7.5-25.7-18.4-46.8-23.5 2.6-2.2 4.3-5.4 3-10.1C66.7 9.9 70 0 70 0S52.8 8.2 49.2 20.2c-.7 2.3-.8 6.5-.2 10.2-1.9-.1-3.8-.2-5.7-.2C18.9 30.3 0 57.3 0 64.9c0 11.5 18.9 34.6 43.2 34.6 31.5 0 58-17.4 71-27.7-.5 3.6-.3 7.5.3 9.6 3.6 12.1 20.9 20.2 20.9 20.2s-3.3-9.9.1-23c1.7-7-2.9-10.7-7.5-12.6zm-91 .1c-4.2 0-7.6-3.4-7.6-7.6S32.8 51 37 51s7.6 3.4 7.6 7.6-3.4 7.5-7.6 7.5z'
        />
        <path
            className='fish_svg__st0'
            d='M76.4 123.2s-3.3-9.9.1-23c.2-.8.3-1.5.4-2.2-6.6 2.4-13.7 4.4-21.1 5.4 3.7 11.8 20.6 19.8 20.6 19.8z'
        />
    </svg>
)

export default SvgFish
