import { FC } from 'react'

const SvgCelery: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 127.6 147.1'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.celery_svg__st0{fill:#fff}'}</style>
        <path
            className='celery_svg__st0'
            d='m126.6 33-.5-1.1.1-.7c.8-4.4-.4-8.7-3.4-11.6-3-3-7.2-4.2-11.6-3.4l-.7.1-1.1-.5c-5.3-2.2-11.9-.7-16.4 3.9L89.8 23v4.8c.3-.2.7-.5 1-.7l4.8-4.8c3.5-3.6 8.6-4.8 12.6-3.1l1.6.7c.3.1.7.2 1 .1l1.2-.2c3.3-.6 6.4.3 8.5 2.4 2.2 2.2 3 5.3 2.4 8.5l-.2 1.2c-.1.3 0 .7.1 1l.7 1.6c1.7 4 .4 9-3.1 12.6l-4.8 4.8c-.3.3-.6.6-.8 1l-3.5 6c-2 3.5-5.4 5.9-9.1 6.6l-4.8.8c-.2 0-.6.1-1 .4l-3.6 2.1c-2.7 1.5-5.6 2-8.3 1.4-.9-.2-1.9.4-2.1 1.3-.1.4-.1.7.1 1v63.1c-.3 1.1-3 8.1-18.5 8.1-15.6 0-18.2-7-18.5-8.1V71.3c-.2-.9-1.2-1.5-2.1-1.3-2.7.6-5.6.1-8.3-1.4l-3.6-2.1c-.4-.3-.8-.4-1-.4l-4.8-.8c-3.7-.7-7.1-3.1-9.1-6.6l-3.5-6c-.2-.3-.5-.7-.8-1l-4.8-4.8c-3.6-3.5-4.8-8.6-3.1-12.6l.7-1.6c.1-.3.2-.7.1-1L5 30.5c-.6-3.3.3-6.4 2.4-8.5 2.2-2.2 5.3-3 8.5-2.4l1.2.2c.3.1.7 0 1-.1l1.6-.7c4-1.7 9-.4 12.6 3.1l4.8 4.8c.3.3.6.5 1 .7v-4.8l-3.2-3.2c-4.5-4.6-11.1-6.1-16.4-3.9l-1.5.6-.7-.1c-4.4-.8-8.7.4-11.6 3.4-3 3-4.2 7.2-3.4 11.6l.1.7L1 33c-2.2 5.3-.7 11.9 3.9 16.4l4.8 4.8c.1.1.1.2.2.2l3.5 6c2.5 4.3 6.8 7.4 11.5 8.3l4.7.8 3.6 2.1c2.4 1.4 5.1 2.2 7.7 2.2.3 0 .6 0 1-.1V136c.1.5 2.4 11 22 11s21.9-10.6 22-11V73.7h.8c2.6 0 5.3-.7 7.7-2.2l3.6-2.1 4.7-.8c4.7-.8 9-3.9 11.5-8.3l3.5-6c0-.1.1-.2.2-.2l4.8-4.8c4.5-4.4 6.1-11 3.9-16.3z'
        />
        <path
            className='celery_svg__st0'
            d='m89.2 51-2.8 4s-.1.2-.2.5l-1 3.7c.6 0 1.2-.2 1.7-.6l5.5-5.5 10.9 3.5c1.1.4 2.4-.3 2.8-1.4.4-1.1-.3-2.4-1.4-2.8L96 49.6l7.1-7 10.9 3.5c1.1.4 2.4-.3 2.8-1.4.4-1.1-.3-2.4-1.4-2.8l-8.7-2.8 7.5-7.4c.9-.9.9-2.2 0-3.1-.9-.9-2.2-.9-3.1 0l-7.6 7.6-3-9.2c-.4-1.1-1.6-1.8-2.8-1.4-1.1.4-1.8 1.6-1.4 2.8l3.7 11.3-7.1 7-3.7 4.3zM27.9 39.6l3.7-11.3c.4-1.1-.3-2.4-1.4-2.8-1.1-.4-2.4.3-2.8 1.4l-3 9.2-7.6-7.6c-.9-.9-2.2-.9-3.1 0-.9.9-.9 2.2 0 3.1l7.5 7.4-8.7 2.8c-1.1.4-1.8 1.6-1.4 2.8.4 1.1 1.6 1.8 2.8 1.4l10.9-3.5 7.1 7-8.9 2.9c-1.1.4-1.8 1.6-1.4 2.8.4 1.1 1.6 1.8 2.8 1.4L35.2 53l5.5 5.5c.5.5 1.1.7 1.7.6l-1-3.7c-.1-.3-.2-.4-.2-.5l-2.8-4-3.4-4.3-7.1-7zM46.1 52.8l1.1 4.1c.9 3.3 2.9 6.3 5.5 8.3v61l-.1 8.5s11.2 4.7 20.9.5c.3 0 .6-.2.9-.4.1 0 .1-.1.2-.1v-.1c.3-.3.4-.7.4-1.1V65.2c2.7-1.9 4.6-4.9 5.5-8.4l1.1-4.1 2.7-3.9c2.7-3.9 3.6-9.2 2.3-14l-1.8-6.7v-7c0-6.4-3.5-12.2-8.9-14.4l-1.1-.4-.4-.5C71.9 2.1 68.1 0 63.9 0s-8.1 2.1-10.6 5.8l-.4.5-1.1.4c-5.3 2.2-8.9 7.9-8.9 14.4v7l-1.8 6.7c-1.3 4.8-.4 10.1 2.3 14l2.7 4zm20 77.5c0 1.1-.9 2-2 2s-2-.9-2-2v-58c0-1.1.9-2 2-2s2 .9 2 2v58zm-15-105.5c-1.1-.5-1.5-1.9-1-2.9.5-1.1 1.9-1.5 2.9-1l8.6 4.4V14.5c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2V25l8.2-4.2c1.1-.5 2.4-.1 2.9 1s.1 2.4-1 2.9l-10 5.3v10l8.2-4.2c1.1-.5 2.4-.1 2.9 1s.1 2.4-1 2.9L66 44.9v7.7c0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2v-7.5L51 39.7c-1.1-.5-1.5-1.9-1-2.9.5-1.1 1.9-1.5 2.9-1l8.6 4.4v-10l-10.4-5.4z'
        />
    </svg>
)

export default SvgCelery
