import { FC, ReactNode } from 'react'

import style from './widgetcontent.module.scss'

interface IWidgetContentProperties {
    children: ReactNode
}

const WidgetContent: FC<IWidgetContentProperties> = ({ children }) => (
    <section className={style.widgetContent}>
        <div className={style.widgetContent__inner}>{children}</div>
    </section>
)

export default WidgetContent
