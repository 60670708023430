export enum IPositionsHorizontal {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum IPositionsVertical {
    BOTTOM = 'bottom',
    CENTER = 'center',
    TOP = 'top',
}

export default interface IConfig {
    buttons: boolean
    buttonsPositionHorizontal: IPositionsHorizontal
    buttonsPositionVertical: IPositionsVertical
    delivery?: string
    giftcards: boolean
    menu: string
    takeout?: string
    venue: string
    widgetPositionHorizontal: string
    widgetPositionVertical: IPositionsVertical
    wrapper?: HTMLElement
    maxVouchers: number
}
