// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .mP4sb{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:stretch;padding:20px 0 0}", "",{"version":3,"sources":["webpack://./components/02.molecules/PaymentOptions/paymentoptions.module.scss"],"names":[],"mappings":"AACA,+CACI,YAAA,CACA,cAAA,CACA,6BAAA,CACA,mBAAA,CACA,gBAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.paymentOptions {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: stretch;\n    padding: 20px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"paymentOptions": "mP4sb"
};
export default ___CSS_LOADER_EXPORT___;
