import { FC } from 'react'
import { FormikErrors, FormikTouched } from 'formik'

import { IFormData, IFormDataValue } from '../../../config/yup/formData.config'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import { isNullOrEmpty } from '../../../utils/guards.utils'
import Card from '../Card/Card'
import FormElement from '../FormElement/FormElement'
import Heading from '../Heading/Heading'
import InputField from '../InputField/InputField'
import InputLabel from '../InputLabel/InputLabel'
import PaymentMethodInput from '../PaymentMethodInput/PaymentMethodInput'
import Text from '../Text/Text'

import style from './formBlock.module.scss'

interface IFormBlockProperties {
    title: string
    introText?: string
    hasRequiredFields: boolean
    disabled?: boolean
    formData: IFormData
    errors: FormikErrors<IFormDataValue>
    touched: FormikTouched<IFormDataValue>
    animationDelayMs?: number
}

const FormBlock: FC<IFormBlockProperties> = ({
    title,
    introText,
    hasRequiredFields,
    formData,
    errors,
    touched,
    disabled,
    animationDelayMs = 0,
}) => {
    const { t } = useMemoTranslation()

    return (
        <Card
            animationDelayMs={animationDelayMs}
            className={style.formBlock}>
            <div className={style.formBlock__header}>
                <Heading
                    className={style.formBlockHeading}
                    element='h2'>
                    {title}
                </Heading>
                {!isNullOrEmpty(introText) && <Text>{introText}</Text>}
                {hasRequiredFields && (
                    <Text
                        size='s'
                        className={style.requiredText}>
                        {t('voucherform.info.required_field')}
                    </Text>
                )}
            </div>

            {
                // current user
                Object.entries(formData).map(([fieldName, userCredentialsItem]) =>
                    userCredentialsItem.type === 'custom_paymentMethod' ? (
                        <PaymentMethodInput
                            key={fieldName}
                            disabled={disabled}
                        />
                    ) : (
                        <FormElement key={fieldName}>
                            {!isNullOrEmpty(userCredentialsItem.label) && (
                                <InputLabel htmlFor={fieldName}>{t(userCredentialsItem.label)}</InputLabel>
                            )}
                            <InputField
                                required={userCredentialsItem.required}
                                errors={errors}
                                touched={touched}
                                options={userCredentialsItem.options}
                                name={fieldName}
                                type={userCredentialsItem.type}
                                placeholder={
                                    isNullOrEmpty(userCredentialsItem.placeHolder)
                                        ? ''
                                        : t(userCredentialsItem.placeHolder)
                                }
                                disabled={disabled}
                            />
                        </FormElement>
                    )
                )
            }
        </Card>
    )
}
export default FormBlock
