import { FC, MouseEventHandler } from 'react'

import style from './widgettriggerbutton.module.scss'

interface IWidgetTriggerButtonProperties {
    label: string
    onClick?: MouseEventHandler<HTMLButtonElement>
}

const WidgetTriggerButton: FC<IWidgetTriggerButtonProperties> = ({ label, onClick }) => (
    <button
        type='button'
        className={style.trigger}
        onClick={onClick}>
        {label}
    </button>
)

export default WidgetTriggerButton
