import { useEffect, useRef } from 'react'

/**
 * @param callback - callback function we want to execute after the delay
 * @param delayMs - delay in ms or null to stop
 * @example
 *  useInterval(() => {
 *    // Your custom callback logic here
 *  }, 5000)
 */
const useInterval = (callback: () => void, delayMs: number | null, immediate = false) => {
    const savedCallback = useRef(callback)
    const interval = useRef<number>()

    useEffect(() => {
        savedCallback.current = callback
        if (immediate) {
            savedCallback.current()
        }
    }, [callback, immediate])

    useEffect(() => {
        if (delayMs !== null) {
            interval.current = setInterval(() => {
                savedCallback.current()
            }, delayMs) as unknown as number
        }

        return () => {
            clearInterval(interval.current)
        }
    }, [delayMs])
}

export default useInterval
