import { FC, HTMLInputTypeAttribute } from 'react'
import classNames from 'classnames'
import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik'

import { IFormDataValue } from '../../../config/yup/formData.config'
import Checkbox from '../Checkbox/Checkbox'
import InputFeedback from '../InputFeedback/InputFeedback'
import SelectBox from '../SelectBox/SelectBox'

import style from './inputField.module.scss'

export interface IInputFieldProperties {
    name: string
    type?: HTMLInputTypeAttribute
    required: boolean
    errors: FormikErrors<IFormDataValue>
    touched: FormikTouched<IFormDataValue>
    className?: string
    placeholder?: string
    disabled?: boolean
    options?: Array<string> | null
    label?: string
}

const InputField: FC<IInputFieldProperties> = ({
    name,
    type = 'text',
    required = false,
    errors,
    touched,
    className,
    placeholder,
    disabled,
    options,
    label,
}) => (
    <>
        <div
            className={classNames(
                style.container,
                required && type !== 'hidden' && style.required,
                disabled === true && style.disabled,
                className
            )}>
            <Field
                id={name}
                disabled={disabled}
                name={name}
                type={type === 'opt-in' ? 'checkbox' : type}
                component={
                    // eslint-disable-next-line no-nested-ternary
                    type === 'checkbox' || type === 'opt-in' ? Checkbox : type === 'select' ? SelectBox : undefined
                }
                label={label}
                className={classNames(
                    // eslint-disable-next-line no-nested-ternary
                    type === 'checkbox' || type === 'opt-in'
                        ? 'checkbox'
                        : type === 'select'
                        ? 'c-selectbox'
                        : style.input,
                    errors[name] !== undefined && touched[name] !== undefined ? style.error : false
                )}
                placeholder={placeholder}
                options={options ?? undefined}
            />
        </div>
        <ErrorMessage
            name={name}
            component={InputFeedback}
        />
    </>
)

export default InputField
