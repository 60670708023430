// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .AA4VB{position:relative;z-index:10;padding:12px 12px 28px;background-color:var(--theme-color);-webkit-user-select:none;user-select:none}#billy-widget#billy-widget#billy-widget .AA4VB *{color:#fff}", "",{"version":3,"sources":["webpack://./components/02.molecules/WidgetIntro/widgetintro.module.scss"],"names":[],"mappings":"AACA,+CACI,iBAAA,CACA,UAAA,CACA,sBAAA,CACA,mCAAA,CACA,wBAAA,CAAA,gBAAA,CAEA,iDACI,UAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.widgetIntro {\n    position: relative;\n    z-index: 10;\n    padding: 12px 12px 28px;\n    background-color: var(--theme-color);\n    user-select: none;\n\n    * {\n        color: #ffffff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"widgetIntro": "AA4VB"
};
export default ___CSS_LOADER_EXPORT___;
