import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './heading.module.scss'

interface IHeadingProperties {
    children: ReactNode
    element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl'
    spacing?: '0' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl'
    align?: 'left' | 'right' | 'center'
    className?: string
}

const Heading: FC<IHeadingProperties> = ({
    children,
    element = 'h1',
    size = 'm',
    spacing = 'm',
    align = 'left',
    className,
}) => {
    const classes = classNames(
        styles.heading,
        styles[`size--${size}`],
        styles[`spacing--${spacing}`],
        styles[`align--${align}`],
        className
    )
    const HeaderElement = element
    return <HeaderElement className={classes}>{children}</HeaderElement>
}

export default Heading
