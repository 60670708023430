import IBillyError from './IBillyError.interface'

export const defaultFetchResult = {
    data: null,
    error: null,
    loading: true,
}

export default interface IFetchResult<T> {
    data: T | null
    error: IBillyError | null
    loading: boolean
}
