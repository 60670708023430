// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .dwxxD{position:relative;display:flex;flex-direction:column;justify-content:stretch;align-items:center;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./components/02.molecules/WidgetContainer/widgetcontainer.module.scss"],"names":[],"mappings":"AACA,+CACI,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,uBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.widgetContainer {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: stretch;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"widgetContainer": "dwxxD"
};
export default ___CSS_LOADER_EXPORT___;
