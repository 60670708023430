import { TFunction } from 'react-i18next'
import axios from 'axios'

import IBillyError from '../structures/IBillyError.interface'

const handleError = (
    error: unknown,
    callBack: (error: IBillyError) => void,
    // eslint-disable-next-line id-length
    t: TFunction<'translation'>
) => {
    if (axios.isAxiosError(error)) {
        callBack({
            message: error.response?.data.message,
            status: error.response?.status,
            response: error.response,
        })
    } else if (error instanceof Error) {
        callBack({
            message: `${error.name}: ${error.message}`,
        })
    } else {
        callBack({
            message: t('general.unknownError'),
            status: 500,
        })
    }
}

export default handleError
