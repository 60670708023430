export { default as SvgAlcohol } from './SvgAlcohol'
export { default as SvgCelery } from './SvgCelery'
export { default as SvgCrustaceans } from './SvgCrustaceans'
export { default as SvgEgg } from './SvgEgg'
export { default as SvgFish } from './SvgFish'
export { default as SvgGluten } from './SvgGluten'
export { default as SvgLupin } from './SvgLupin'
export { default as SvgMilk } from './SvgMilk'
export { default as SvgMolluscs } from './SvgMolluscs'
export { default as SvgMustard } from './SvgMustard'
export { default as SvgPeanuts } from './SvgPeanuts'
export { default as SvgSesame } from './SvgSesame'
export { default as SvgSoybeans } from './SvgSoybeans'
export { default as SvgSulphurDioxide } from './SvgSulphurDioxide'
export { default as SvgTreeNuts } from './SvgTreeNuts'
