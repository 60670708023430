import { FC } from 'react'

const SvgLupin: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 75.1 162'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.lupin_svg__st0{fill:#fff}'}</style>
        <path
            className='lupin_svg__st0'
            d='M48.2 117.1c0 5.9-7.6 16-10.8 16-3.2 0-10.8-10.1-10.8-16s4.8-10.7 10.8-10.7 10.8 4.8 10.8 10.7zM71.9 127.4c-4.2 4.8-16.7 6.9-19 4.3-2.3-2.6-.5-16.8 3.8-21.6s11.1-4.8 15.3 0 4.1 12.5-.1 17.3zM3.2 127.4c4.2 4.8 16.7 6.9 19 4.3 2.3-2.6.5-16.8-3.8-21.6s-11.1-4.8-15.3 0-4.2 12.5.1 17.3zM46.8 87.8c0 5.2-6.6 13.9-9.4 13.9-2.8 0-9.4-8.8-9.4-13.9 0-5.2 4.2-9.3 9.4-9.3s9.4 4.1 9.4 9.3zM67.9 96.7c-3.7 4.2-14.6 6-16.6 3.7-2-2.3-.4-14.7 3.3-18.8 3.7-4.2 9.6-4.2 13.3 0 3.6 4.1 3.6 10.9 0 15.1zM7.2 96.7c3.7 4.2 14.6 6 16.6 3.7 2-2.3.4-14.7-3.3-18.8-3.7-4.2-9.6-4.2-13.3 0-3.6 4.1-3.6 10.9 0 15.1zM45.4 61.8c0 4.4-5.7 12-8.1 12s-8.1-7.5-8.1-12 3.6-8 8.1-8 8.1 3.6 8.1 8zM64 69.5c-3.2 3.6-12.5 5.1-14.3 3.2-1.7-1.9-.3-12.6 2.8-16.2 3.2-3.6 8.3-3.6 11.4 0 3.3 3.6 3.3 9.4.1 13zM11.1 69.5c3.2 3.6 12.5 5.1 14.3 3.2s.3-12.6-2.8-16.2c-3.2-3.6-8.3-3.6-11.4 0-3.3 3.6-3.3 9.4-.1 13zM43.9 38.9c0 3.6-4.6 9.8-6.6 9.8s-6.6-6.2-6.6-9.8 3-6.6 6.6-6.6c3.6 0 6.6 3 6.6 6.6zM59.7 45.2c-2.6 2.9-10.3 4.2-11.7 2.6-1.4-1.6-.3-10.3 2.3-13.3s6.8-2.9 9.4 0 2.6 7.7 0 10.7zM15.4 45.2c2.6 2.9 10.3 4.2 11.7 2.6 1.4-1.6.3-10.3-2.3-13.3s-6.8-2.9-9.4 0-2.6 7.7 0 10.7zM42 20.8c0 2.7-3.4 7.2-4.9 7.2s-4.9-4.5-4.9-7.2 2.2-4.8 4.9-4.8 4.9 2.1 4.9 4.8zM42 4.8c0 2.7-3.4 7.2-4.9 7.2s-4.9-4.5-4.9-7.2 2.3-4.8 5-4.8S42 2.2 42 4.8zM54.6 25.4c-1.9 2.2-7.6 3.1-8.6 1.9s-.2-7.6 1.7-9.8 5-2.2 6.9 0 1.9 5.8 0 7.9zM20.5 25.4c1.9 2.2 7.6 3.1 8.6 1.9 1-1.2.2-7.6-1.7-9.8-1.9-2.2-5-2.2-6.9 0-1.9 2.2-1.9 5.8 0 7.9zM37.2 139.2c-2.4 0-4.3 1.9-4.3 4.3v14.2c0 2.4 1.9 4.3 4.3 4.3s4.3-1.9 4.3-4.3v-14.2c0-2.4-2-4.3-4.3-4.3z'
        />
    </svg>
)

export default SvgLupin
