import { FC } from 'react'
import classNames from 'classnames'

import IProduct from '../../../structures/IProduct.interface'
import { isNullOrEmpty } from '../../../utils/guards.utils'
import AllergensContainer from '../Allergens/AllergensContainer'
import Currency from '../Currency/Currency'
import Text from '../Text/Text'

import style from './product.module.scss'

interface IProductProperties {
    product: IProduct
}

const Product: FC<IProductProperties> = ({ product }) => (
    <div className={style.product}>
        <div className={style.product__inner}>
            <div
                className={classNames(
                    style['product__inner--left'],
                    isNullOrEmpty(product.description) ? undefined : style.withDescription
                )}>
                <Text className={style.name}>{product.name}</Text>
                <Text
                    className={style.description}
                    spacing='0'>
                    {product.description}
                </Text>
                {!isNullOrEmpty(product.allergens) && <AllergensContainer allergenNames={product.allergens} />}
            </div>

            <div className={style['product__inner--right']}>
                <div>
                    <Text className={style.price}>
                        <Currency>{product.price}</Currency>
                    </Text>
                </div>
                {product.images?.small != null && (
                    <div className={style.image}>
                        <img
                            loading='lazy'
                            src={product.images.small}
                            alt={product.name}
                        />
                    </div>
                )}
            </div>
        </div>
    </div>
)

export default Product
