import { FC, ReactNode } from 'react'

import IConfig from '../structures/IConfig.interface'
import { mergeConfigs } from '../utils/config.utils'
import createGenericContext from '../utils/genericContext.utils'

const [useConfigContext, ConfigContextProvider] = createGenericContext<IConfig>()

interface IConfigProviderProperties {
    children: ReactNode
    userConfig: IConfig
}

const ConfigProvider: FC<IConfigProviderProperties> = ({ children, userConfig }) => (
    <ConfigContextProvider value={mergeConfigs(userConfig)}>{children}</ConfigContextProvider>
)

export { ConfigProvider, useConfigContext }
