import { FC, useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import useMemoTranslation from '../../../hooks/useMemoTranslation'

import Allergen from './Allergen'

import style from './allergens.module.scss'

interface IAllergensProperties {
    allergenNames: Array<string>
}

const AllergensContainer: FC<IAllergensProperties> = ({ allergenNames }) => {
    const { t } = useMemoTranslation()
    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <button
                type='button'
                onClick={toggleOpen}
                className={classNames(style.trigger, isOpen ? style.isOpen : undefined)}>
                {t('allergens.trigger')}
            </button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className={style.allergensContainer}
                        initial={{ opacity: 0, maxHeight: 0 }}
                        animate={{ opacity: 1, maxHeight: 1000 }}
                        exit={{ opacity: 0, maxHeight: 0 }}>
                        {allergenNames.map((allergenName, index) => (
                            <Allergen
                                animationDelayMs={index * 50}
                                key={allergenName}
                                allergenName={allergenName}
                            />
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default AllergensContainer
