import { FC, ReactNode } from 'react'

import style from './widgetcontainer.module.scss'

interface IWidgetContainerProperties {
    children: ReactNode
}

const WidgetContainer: FC<IWidgetContainerProperties> = ({ children }) => (
    <main className={style.widgetContainer}>{children}</main>
)

export default WidgetContainer
