import { FC } from 'react'

const SvgSesame: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 136.5 133.5'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.sesame_svg__st0{fill:#fff}'}</style>
        <path
            className='sesame_svg__st0'
            d='M58.5 35.4C68.7 40.3 80.4 37 84.7 28c4.3-9-.4-20.3-10.6-25.2C64-2.1 39.9-.1 37.4 5.2c-2.1 4.4 10.9 25.3 21.1 30.2zm12-30c.8-1.1 2.3-1.4 3.5-.6 6.7 4.7 8.8 10.4 9.4 14.3.6 4.2-.3 7.3-.5 7.6 0 .1-.1.2-.1.3-.5 1.1-1.8 1.7-3 1.3-1.3-.4-2-1.8-1.6-3.1.1-.4 2.8-9.3-7.1-16.3-1.1-.8-1.4-2.4-.6-3.5zM35.8 61.5c2.8-10.9-3.9-34.1-9.5-35.6C21.4 24.7 3.5 41.6.7 52.5s2.8 21.8 12.4 24.3c9.7 2.5 19.9-4.4 22.7-15.3zM11.9 72c.2-1.4 1.4-2.3 2.8-2.2.4 0 9.7.9 14.6-10.1.6-1.3 2-1.8 3.3-1.3 1.3.6 1.8 2 1.3 3.3-3.3 7.5-8.4 10.7-12.1 12-4 1.4-7.2 1.1-7.5 1-.1 0-.2 0-.3-.1-1.5-.2-2.3-1.4-2.1-2.6zM124.1 77.4c9.6-2.9 14.7-14 11.5-24.8-3.2-10.8-21-27.2-26.5-25.5-4.7 1.4-11.3 25.1-8.1 35.9 3.1 10.8 13.5 17.2 23.1 14.4zm-2.4-6.8c.4-.2 8.8-4.2 7.2-16.2-.2-1.4.8-2.6 2.1-2.8 1.4-.2 2.6.8 2.8 2.1 1 8.1-1.7 13.5-4.2 16.5-2.7 3.3-5.6 4.6-5.9 4.8-.1 0-.2.1-.3.1-1.2.4-2.5-.2-3-1.4-.5-1 .1-2.5 1.3-3.1zM86.7 66C81 56.2 59.9 44.4 54.9 47.3c-4.3 2.5-5.2 27.1.5 36.8 5.7 9.7 17.3 13.6 25.9 8.5C90 87.7 92.4 75.7 86.7 66zm-6.2 24.6c-.1.1-.2.1-.3.2-1.1.6-2.4.4-3.2-.6-.8-1.1-.6-2.6.4-3.5.3-.3 7.5-6.2 3.3-17.4-.5-1.3.2-2.7 1.4-3.2 1.3-.5 2.7.2 3.2 1.4 2.9 7.7 1.5 13.5-.2 17.1-1.8 3.8-4.3 5.8-4.6 6zM44.1 99.1c-9.7-5.7-33.9-5.7-36.8-.6-2.5 4.3 8.8 26.1 18.5 31.8s21.7 3.3 26.8-5.3c5-8.6 1.2-20.2-8.5-25.9zm6.7 24.5c0 .1-.1.2-.1.3-.6 1.1-1.9 1.5-3.1 1-1.3-.5-1.8-2-1.3-3.2.2-.4 3.5-9.1-5.7-16.8-1.1-.9-1.2-2.4-.3-3.5.9-1.1 2.4-1.2 3.5-.3 6.3 5.3 7.9 11.1 8.2 15 .1 4.2-1.1 7.2-1.2 7.5zM93.5 99.2c-9.7 5.8-13.3 17.5-8.2 26 5.1 8.6 17.2 10.8 26.8 5 9.7-5.8 21.2-27 18.2-32-2.6-4.2-27.2-4.8-36.8 1zm17 29.7c-7.6 3-13.5 1.7-17.1 0-3.8-1.8-5.9-4.3-6.1-4.6-.1-.1-.1-.2-.2-.3-.6-1.1-.4-2.4.6-3.2 1.1-.8 2.6-.7 3.5.4.3.3 6.3 7.5 17.5 3 1.3-.5 2.7.1 3.2 1.4.5 1.4-.1 2.8-1.4 3.3z'
        />
    </svg>
)

export default SvgSesame
