// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .BBBxa{border-radius:var(--border-radius);background-color:#fff;box-shadow:rgba(0,0,0,.05) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px}", "",{"version":3,"sources":["webpack://./components/01.atoms/Card/card.module.scss"],"names":[],"mappings":"AACA,+CACI,kCAAA,CACA,qBAAA,CAEA,wEAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.card {\n    border-radius: var(--border-radius);\n    background-color: #ffffff;\n    //box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);\n    box-shadow: rgb(0 0 0 / 5%) 0 4px 6px -1px, rgb(0 0 0 / 6%) 0 2px 4px -1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"card": "BBBxa"
};
export default ___CSS_LOADER_EXPORT___;
