import { FC } from 'react'
import { FaMinus } from '@react-icons/all-files/fa/FaMinus'
import { FaPlus } from '@react-icons/all-files/fa/FaPlus'

import { useVouchersContext } from '../../../context/VouchersContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import Button from '../Button/Button'
import Card from '../Card/Card'
import Currency from '../Currency/Currency'
import Heading from '../Heading/Heading'
import Text from '../Text/Text'

import style from './voucherQuantity.module.scss'

const VoucherQuantitySelector: FC<Record<string, never>> = () => {
    const { t } = useMemoTranslation()
    const { amount, setAmount, activeVoucher, totalPrice, maxVouchers } = useVouchersContext()

    return (
        <div className={style.voucher__quantity__container}>
            {activeVoucher === null ? null : (
                <>
                    <Card className={style.voucher__quantity__card}>
                        <div className={style.voucher__count__container}>
                            <Heading element='h2'>{t('voucherForm.quantity')}</Heading>

                            <div className={style.voucher__count}>
                                <Button
                                    className={style.voucher__count__button}
                                    onClick={() => {
                                        setAmount((previous) => Math.max(1, previous - 1))
                                    }}>
                                    <FaMinus />
                                </Button>
                                <Text
                                    className={style.voucher__count__total}
                                    weight='bold'
                                    size='l'
                                    align='center'
                                    spacing='0'>
                                    {amount}
                                </Text>
                                <Button
                                    disabled={amount === maxVouchers}
                                    className={style.voucher__count__button}
                                    onClick={() => {
                                        setAmount((previous) => Math.min(maxVouchers, previous + 1))
                                    }}>
                                    <FaPlus />
                                </Button>
                            </div>
                        </div>
                    </Card>
                    <Card className={style.voucher__quantity__total__card}>
                        <Heading element='h2'>Total:</Heading>

                        <Text
                            size='l'
                            weight='bold'
                            align='right'
                            spacing='0'>
                            <Currency>{totalPrice}</Currency>
                        </Text>
                    </Card>
                </>
            )}
        </div>
    )
}

export default VoucherQuantitySelector
