import { Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from 'react'

import IVoucher from '../structures/IVoucher.interface'
import IVoucherPurchase from '../structures/IVoucherPurchase.interface'
import createGenericContext from '../utils/genericContext.utils'

import { useConfigContext } from './ConfigContext'

interface IVouchersContext {
    giftCardsEnabled: boolean
    activeVoucher: IVoucher | null
    setActiveVoucher: Dispatch<SetStateAction<IVoucher | null>>
    voucherPurchaseId: string | null
    setVoucherPurchaseId: Dispatch<SetStateAction<string | null>>
    voucherPurchasePaymentUrl: string | null
    setVoucherPurchasePaymentUrl: Dispatch<SetStateAction<string | null>>
    voucherPurchase: IVoucherPurchase | null
    setVoucherPurchase: Dispatch<SetStateAction<IVoucherPurchase | null>>
    amount: number
    setAmount: Dispatch<SetStateAction<number>>
    totalPrice: number | null
    maxVouchers: number
}

const [useVouchersContext, VouchersContextProvider] = createGenericContext<IVouchersContext>()

interface IVouchersProviderProperties {
    children: ReactNode
}

const VouchersProvider: FC<IVouchersProviderProperties> = ({ children }) => {
    const config = useConfigContext()
    const [activeVoucher, setActiveVoucher] = useState<IVouchersContext['activeVoucher']>(null)
    const [voucherPurchaseId, setVoucherPurchaseId] = useState<IVouchersContext['voucherPurchaseId']>(null)
    const [voucherPurchasePaymentUrl, setVoucherPurchasePaymentUrl] =
        useState<IVouchersContext['voucherPurchasePaymentUrl']>(null)
    const [voucherPurchase, setVoucherPurchase] = useState<IVouchersContext['voucherPurchase']>(null)
    const [amount, setAmount] = useState<number>(1)
    const totalPrice = activeVoucher !== null ? amount * activeVoucher.amount : null

    const vouchersContextValue = useMemo(
        () => ({
            giftCardsEnabled: config.giftcards,
            activeVoucher,
            setActiveVoucher,
            voucherPurchaseId,
            setVoucherPurchaseId,
            voucherPurchasePaymentUrl,
            setVoucherPurchasePaymentUrl,
            voucherPurchase,
            setVoucherPurchase,
            amount,
            setAmount,
            totalPrice,
            maxVouchers: config.maxVouchers,
        }),
        [
            config.giftcards,
            activeVoucher,
            voucherPurchaseId,
            voucherPurchasePaymentUrl,
            voucherPurchase,
            amount,
            totalPrice,
            config.maxVouchers,
        ]
    )

    return <VouchersContextProvider value={vouchersContextValue}>{children}</VouchersContextProvider>
}

export { useVouchersContext, VouchersProvider }
