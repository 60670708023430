// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .oJYYl{display:flex;justify-content:center;align-items:center;padding:0;border:none;background:rgba(0,0,0,0);color:#fff;cursor:pointer;transition:transform 200ms ease-in-out}#billy-widget#billy-widget#billy-widget .oJYYl:hover{transform:scale(1.1)}", "",{"version":3,"sources":["webpack://./components/01.atoms/CloseButton/closebutton.module.scss"],"names":[],"mappings":"AACA,+CACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CACA,WAAA,CACA,wBAAA,CACA,UAAA,CACA,cAAA,CACA,sCAAA,CAEA,qDACI,oBAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.close {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0;\n    border: none;\n    background: transparent;\n    color: #ffffff;\n    cursor: pointer;\n    transition: transform 200ms ease-in-out;\n\n    &:hover {\n        transform: scale(1.1);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"close": "oJYYl"
};
export default ___CSS_LOADER_EXPORT___;
