import { Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from 'react'

import WidgetType from '../structures/WidgetType.enum'
import createGenericContext from '../utils/genericContext.utils'

interface IGlobalsContext {
    activeWidget: WidgetType | null
    setActiveWidget: Dispatch<SetStateAction<WidgetType | null>>
    widgetOpen: boolean
    setWidgetOpen: Dispatch<SetStateAction<boolean>>
}

const [useGlobalsContext, GlobalsContextProvider] = createGenericContext<IGlobalsContext>({
    activeWidget: null,
    setActiveWidget: () => {
        console.warn('setActiveWidget not implemented')
    },
    widgetOpen: false,
    setWidgetOpen: () => {
        console.warn('setWidgetOpen not implemented')
    },
})

interface IGlobalsProviderProperties {
    children: ReactNode
}

const GlobalsProvider: FC<IGlobalsProviderProperties> = ({ children }) => {
    const [widgetOpen, setWidgetOpen] = useState<IGlobalsContext['widgetOpen']>(false)
    const [activeWidget, setActiveWidget] = useState<IGlobalsContext['activeWidget']>(null)
    const globalsContextValue = useMemo(
        () => ({
            activeWidget,
            setActiveWidget,
            widgetOpen,
            setWidgetOpen,
        }),
        [activeWidget, widgetOpen]
    )

    return <GlobalsContextProvider value={globalsContextValue}>{children}</GlobalsContextProvider>
}

export { GlobalsProvider, useGlobalsContext }
