import { FC } from 'react'

import useMemoTranslation from '../../../hooks/useMemoTranslation'
import Text from '../Text/Text'

import style from './InputFeedback.module.scss'

interface IInputFeedbackProperties {
    children?: string
}

const InputFeedback: FC<IInputFeedbackProperties> = ({ children }) => {
    const { t } = useMemoTranslation()
    if (children === undefined) {
        return null
    }
    return (
        <Text
            className={style.errorMessage}
            size='s'>
            {t(children)}
        </Text>
    )
}

export default InputFeedback
