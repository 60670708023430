// @Improvement: replace with a price formatter
import { FC, ReactNode } from 'react'

interface ICurrencyProperties {
    children: ReactNode
}

const Currency: FC<ICurrencyProperties> = ({ children }) =>
    children === undefined ? null : <span>{`€${children}`}</span>

export default Currency
