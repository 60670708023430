import { Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from 'react'

import { IFormDataValue } from '../config/yup/formData.config'
import createGenericContext from '../utils/genericContext.utils'

const [useFormValuesContext, FormValuesContextProvider] = createGenericContext<{
    savedFormValues: IFormDataValue | null
    setSavedFormValues: Dispatch<SetStateAction<IFormDataValue | null>>
}>()

interface IVouchersProviderProperties {
    children: ReactNode
}

const FormValuesProvider: FC<IVouchersProviderProperties> = ({ children }) => {
    const [savedFormValues, setSavedFormValues] = useState<IFormDataValue | null>(null)

    const formValuesContextValue = useMemo(
        () => ({
            savedFormValues,
            setSavedFormValues,
        }),
        [savedFormValues]
    )

    return <FormValuesContextProvider value={formValuesContextValue}>{children}</FormValuesContextProvider>
}

export { FormValuesProvider, useFormValuesContext }
