import { FC } from 'react'

const SvgMolluscs: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 137.8 119.5'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <path
            d='M125.9 36.5c.2-6.3-2.1-12.3-6.5-16.9-4.4-4.6-10.6-7.3-17-7.3h-.6C97.6 4.7 89.7 0 81 0c-4.3 0-8.6 1.2-12.3 3.5C65 1.2 60.8 0 56.5 0c-8.7 0-16.6 4.7-20.7 12.3h-.6c-6.4 0-12.6 2.7-17 7.3s-6.7 10.6-6.5 16.9C6 39.5 1.8 44.8.1 51.1c-.1.3-.1.6-.1 1 .1 1.2 3 30 37.8 49.9v14.5c0 1.6 1.3 3 3 3H97c1.6 0 3-1.3 3-3v-14c17.4-11 30-27.7 37.4-49.7 2.4-7.2-4.8-12.8-11.5-16.3zm-77.6 53c-.5.2-1 .3-1.6.3-1.9 0-3.7-1.2-4.3-3L24.5 37c-.9-2.4.4-5 2.8-5.9 2.4-.9 5 .4 5.9 2.8L51 83.7c.9 2.3-.4 5-2.7 5.8zm25.2-7c0 2.5-2.1 4.6-4.6 4.6-2.5 0-4.6-2.1-4.6-4.6V20.3c0-2.5 2.1-4.6 4.6-4.6 2.5 0 4.6 2.1 4.6 4.6v62.2zM114 36.8 96.2 86.6c-.7 1.9-2.4 3-4.3 3-.5 0-1-.1-1.6-.3-2.4-.9-3.6-3.5-2.8-5.9l17.8-49.7c.9-2.4 3.5-3.6 5.9-2.8 2.4.9 3.7 3.5 2.8 5.9z'
            style={{
                fill: '#fff',
            }}
        />
    </svg>
)

export default SvgMolluscs
