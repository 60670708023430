import { FC } from 'react'
import { GrMoney } from '@react-icons/all-files/gr/GrMoney'
import classNames from 'classnames'

import { useFormValuesContext } from '../../../context/FormValuesContext'
import { useVenueContext } from '../../../context/VenueContext'
import useMemoTranslation from '../../../hooks/useMemoTranslation'
import IVoucher from '../../../structures/IVoucher.interface'
import { isNullOrEmpty } from '../../../utils/guards.utils'
import Card from '../Card/Card'
import Currency from '../Currency/Currency'
import Text from '../Text/Text'

import style from './voucher.module.scss'

interface IVoucherCardProperties {
    clickable?: boolean
    voucher: IVoucher
}

const VoucherCard: FC<IVoucherCardProperties> = ({ clickable = true, voucher }) => {
    const { t } = useMemoTranslation()
    const { savedFormValues } = useFormValuesContext()
    const { data: venue } = useVenueContext()

    return (
        <Card className={classNames(style.voucher, clickable && style.clickable)}>
            <div className={style.voucher__header}>
                <div className={style.voucher__header__icon}>
                    {!isNullOrEmpty(venue?.theming?.logo) ? (
                        <img
                            src={venue?.theming.logo}
                            alt={venue?.name}
                        />
                    ) : (
                        <GrMoney />
                    )}
                </div>
                <div>
                    <Text
                        size='s'
                        spacing='0'
                        align='right'>
                        {voucher.name}
                    </Text>
                    <Text
                        weight='bold'
                        size='xxl'
                        spacing='0'
                        align='right'>
                        <Currency>{voucher.amount}</Currency>
                    </Text>
                </div>
            </div>

            <div className={style.voucher__description}>
                {!isNullOrEmpty(voucher.description) && <Text size='s'>{voucher.description}</Text>}
            </div>

            <div className={classNames(style.voucher__button, clickable && style.clickable)}>
                {clickable ? (
                    <>
                        <Text
                            weight='bold'
                            spacing='0'>
                            {t('button.buy_voucher.cta')}
                        </Text>
                        <span className={style.voucher__button__arrow} />
                    </>
                ) : (
                    <Text
                        size='s'
                        spacing='0'>
                        {!isNullOrEmpty(savedFormValues?.receiverFirstName)
                            ? t('giftcard.info.with_receiver_name.label', {
                                  venue: venue?.name,
                                  receiverFirstName: savedFormValues?.receiverFirstName,
                              })
                            : t('giftcard.info.label', { venue: venue?.name })}
                    </Text>
                )}
            </div>
        </Card>
    )
}

export default VoucherCard
