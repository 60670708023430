import IConfig, { IPositionsHorizontal, IPositionsVertical } from '../structures/IConfig.interface'

export const defaultConfig: Pick<
    IConfig,
    | 'buttons'
    | 'buttonsPositionHorizontal'
    | 'buttonsPositionVertical'
    | 'widgetPositionHorizontal'
    | 'widgetPositionVertical'
    | 'wrapper'
    | 'maxVouchers'
> = {
    buttons: true,
    buttonsPositionHorizontal: IPositionsHorizontal.RIGHT,
    buttonsPositionVertical: IPositionsVertical.BOTTOM,
    widgetPositionHorizontal: IPositionsHorizontal.RIGHT,
    widgetPositionVertical: IPositionsVertical.BOTTOM,
    maxVouchers: 1,
}

// merge the default (or given config) with the user's config
// first check if the values are allowed, else take first one of allowed values for the given key
export const mergeConfigs: (userConfig: IConfig) => IConfig = (userConfig) => ({
    ...defaultConfig,
    ...userConfig,
})

interface IThemeConfig {
    '--theme-color': string
}

// apply the theming by setting the variables
export const applyTheme = (themeConfig: IThemeConfig) => {
    for (const [key, value] of Object.entries(themeConfig)) {
        defaultConfig.wrapper?.style.setProperty(key, value)
    }
}
