type VenueIdToEndpointUriType = (id: string) => string

const { REACT_APP_API_URL } = process.env
export const VENUE_BY_ID: VenueIdToEndpointUriType = (venueId) => `${REACT_APP_API_URL}/api/venues/${venueId}`
export const EVENT_BY_ID: VenueIdToEndpointUriType = (eventId) => `${REACT_APP_API_URL}/api/events/${eventId}`
export const EVENT_CATALOGUE: VenueIdToEndpointUriType = (eventId) =>
    `${REACT_APP_API_URL}/api/events/${eventId}/categorygroups`
export const VOUCHER_PURCHASE_OPTIONS: VenueIdToEndpointUriType = (venueId) =>
    `${REACT_APP_API_URL}/api/venues/${venueId}/voucherpurchaseoptions`
export const VOUCHER_PURCHASE_PSPS: VenueIdToEndpointUriType = (venueId) =>
    `${REACT_APP_API_URL}/api/venues/${venueId}/voucherpurchasepsps`
export const VOUCHER_PURCHASE_CREATE: VenueIdToEndpointUriType = (venueId) =>
    `${REACT_APP_API_URL}/api/venues/${venueId}/voucherpurchases`
export const VOUCHER_PURCHASE_BY_ID: VenueIdToEndpointUriType = (voucherPurchaseId) =>
    `${REACT_APP_API_URL}/api/voucherpurchases/${voucherPurchaseId}`
export const VOUCHER_PAYMENT_CREATE: VenueIdToEndpointUriType = (voucherPurchaseId) =>
    `${REACT_APP_API_URL}/api/voucherpurchases/${voucherPurchaseId}/payment`
