import { FC } from 'react'
import { useFormikContext } from 'formik'

import { IFormDataValue } from '../../../config/yup/formData.config'
import IVenuePsp from '../../../structures/IVenuePsp.interface'
import PspType from '../../../structures/PSPType.enum'

import payconiqLogo from './svg/payconic.svg'
import PspMethod from './PspMethod'

interface IPspUniqueProperties {
    onPaymentOptionSelect: (pspType: PspType, methodId: string | null) => void
    disabled?: boolean
    fee?: string | undefined
}

type IPspProperties = IPspUniqueProperties & Pick<IVenuePsp, 'type' | 'methods'>

const Psp: FC<IPspProperties> = ({ type, methods, onPaymentOptionSelect, disabled = false, fee }) => {
    const { values } = useFormikContext<IFormDataValue>()
    const isPspActive = (pspType, methodId) => values.pspType === pspType && values.pspMethod === methodId

    // PAYCONIQ
    if (type === PspType.PAYCONIQ) {
        return (
            <PspMethod
                active={isPspActive(type, '')}
                onClick={() => {
                    onPaymentOptionSelect(type, '')
                }}
                disabled={disabled}
                image={payconiqLogo}
                fee={fee}
                label='Payconiq'
            />
        )
    }

    // DEFAULT
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {methods?.map((method) => {
                // APPLE PAY PSP BUTTON: check if device can make payments with Apple Pay
                if (method.id === 'applepay' && window.ApplePaySession?.canMakePayments() !== true) {
                    return null
                }

                return (
                    <PspMethod
                        active={isPspActive(type, method.id)}
                        key={`${type}-${method.id}`}
                        onClick={() => {
                            onPaymentOptionSelect(type, method.id)
                        }}
                        disabled={disabled}
                        image={method.image}
                        label={method.name}
                        fee={fee}
                    />
                )
            }) ?? null}
        </>
    )
}

export default Psp
