import { FC } from 'react'
import { motion } from 'framer-motion'

import useMemoTranslation from '../../../hooks/useMemoTranslation'

import ALLERGENS from './allergens.data'

import style from './allergens.module.scss'

interface IAllergenProperties {
    allergenName: string
    animationDelayMs: number
}

const Allergen: FC<IAllergenProperties> = ({ allergenName, animationDelayMs }) => {
    const { t } = useMemoTranslation()
    const allergenItem = ALLERGENS.find((allergen) => allergen.name === allergenName)

    if (allergenItem === undefined) {
        return null
    }

    return (
        <motion.div
            className={style.allergen}
            initial={{
                opacity: 0,
                y: 10,
            }}
            animate={{
                opacity: 1,
                y: 0,
                transition: {
                    delay: animationDelayMs / 1000,
                },
            }}>
            <div
                className={style.allergen__icon}
                style={{ backgroundColor: allergenItem.color }}>
                {allergenItem.icon}
            </div>
            <div className={style.allergen__label}>{t(allergenItem.display_name)}</div>
        </motion.div>
    )
}

export default Allergen
