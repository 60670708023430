import { FC, useEffect } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { useConfigContext } from '../../../context/ConfigContext'
import { useGlobalsContext } from '../../../context/GlobalsContext'
import { useVenueContext } from '../../../context/VenueContext'
import useCustomEvents from '../../../hooks/useCustomEvents'
import useTheme from '../../../hooks/useTheme'
import MenuWidget from '../../../pages/MenuWidget/MenuWidget'
import VouchersWidget from '../../../pages/VoucherWidget/VouchersWidget'
import WidgetType from '../../../structures/WidgetType.enum'
import WidgetTriggers from '../../02.molecules/WidgetTriggers/WidgetTriggers'

import style from './widgets.module.scss'

const Widgets: FC<Record<string, never>> = () => {
    const config = useConfigContext()
    const { loading: venueLoading } = useVenueContext()

    useCustomEvents()
    useTheme()

    const { activeWidget, setActiveWidget, widgetOpen, setWidgetOpen } = useGlobalsContext()

    useEffect(() => {
        switch (activeWidget) {
            case WidgetType.WIDGET_DELIVERY: {
                window.open(`${process.env.REACT_APP_BILLY_ROOT}/events/${config.delivery}`, '_blank')
                break
            }
            case WidgetType.WIDGET_TAKEOUT: {
                window.open(`${process.env.REACT_APP_BILLY_ROOT}/events/${config.takeout}`, '_blank')
                break
            }
            case WidgetType.WIDGET_BILLY: {
                window.open('https://meet.orderbilly.com', '_blank')
                break
            }
            default: {
                setWidgetOpen(activeWidget != null)
            }
        }
    }, [activeWidget, config.takeout, config.delivery, setWidgetOpen])

    if (venueLoading) {
        return null
    }

    return (
        <>
            <AnimatePresence>
                {widgetOpen && (
                    <motion.section
                        className={classNames(
                            style.container,
                            style[`position__horizontal--${config.widgetPositionHorizontal}`],
                            style[`position__vertical--${config.widgetPositionVertical}`]
                        )}
                        initial={{
                            width: 0,
                            height: 0,
                            opacity: 0,
                        }}
                        animate={{
                            width: '100%',
                            height: '100%',
                            opacity: 1,
                            transition: {
                                duration: 0.45,
                            },
                        }}
                        exit={{
                            width: 0,
                            height: 0,
                            transition: {
                                duration: 0.2,
                                onComplete: () => {
                                    setActiveWidget(null)
                                },
                            },
                        }}>
                        {activeWidget === WidgetType.WIDGET_VOUCHERS && <VouchersWidget />}

                        {activeWidget === WidgetType.WIDGET_MENU && <MenuWidget />}
                    </motion.section>
                )}
            </AnimatePresence>

            {config.buttons && !widgetOpen && <WidgetTriggers />}
        </>
    )
}

export default Widgets
