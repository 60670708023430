// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .X7oKB{position:relative;display:flex;justify-content:center;align-items:center;width:100%}#billy-widget#billy-widget#billy-widget .X7oKB .rTRzq{z-index:10;width:100%;max-width:350px;padding:30px 10px}#billy-widget#billy-widget#billy-widget .X7oKB::before{content:\"\";position:absolute;top:0;right:0;bottom:50%;left:0;z-index:0;width:100%;background:var(--theme-color)}", "",{"version":3,"sources":["webpack://./components/03.organisms/Credentials/usercredentials.module.scss"],"names":[],"mappings":"AACA,+CACI,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CAEA,sDACI,UAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CAGJ,uDACI,UAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,UAAA,CACA,MAAA,CACA,SAAA,CACA,UAAA,CACA,6BAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.chosenVoucher {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n\n    .chosenVoucher__inner {\n        z-index: 10;\n        width: 100%;\n        max-width: 350px;\n        padding: 30px 10px;\n    }\n\n    &::before {\n        content: '';\n        position: absolute;\n        top: 0;\n        right: 0;\n        bottom: 50%;\n        left: 0;\n        z-index: 0;\n        width: 100%;\n        background: var(--theme-color);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"chosenVoucher": "X7oKB",
	"chosenVoucher__inner": "rTRzq"
};
export default ___CSS_LOADER_EXPORT___;
