import { FC } from 'react'

const SvgCrustaceans: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 145 124'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <path
            d='m142.1 84.9-30.1-7c.7-2 1.1-4 1.3-5.9l26.1-5.3c1.1-.2 2-.9 2.5-1.9.5-1 .6-2.1.2-3.1L134 40.2c7.4-8 5.7-22-4-31.6-9-9-22-11.2-30.2-5.2-1.1.8-1.6 2.1-1.5 3.4.1 1.3 1 2.5 2.2 3 12.4 5.6 13.2 12.8 13 15.3-.2 0-.4 0-.4-.1-4.2-3.6-9.2-6.8-14.9-9.3-1.2-.5-2.6-.4-3.7.3s-1.7 2-1.6 3.3c.4 6.3 3.5 12.8 8.5 17.8 5.6 5.6 12.7 8.5 19.3 8.5 2.4 0 4.8-.4 6.9-1.2l6.1 15.7-20.7 4.3C109.8 50.7 93.1 40.2 72.8 40h-.6c-20.3.2-37 10.7-40.2 24.5l-20.7-4.2 6.1-15.7c2.2.8 4.5 1.2 6.9 1.2 6.5 0 13.7-2.9 19.3-8.5 5-5 8.1-11.5 8.5-17.8.1-1.3-.5-2.6-1.6-3.3-1.1-.7-2.5-.9-3.7-.3-5.7 2.6-10.7 5.7-14.9 9.3-.1 0-.2.1-.4.1-.2-2.5.6-9.7 13-15.3 1.2-.5 2-1.7 2.2-3 .1-1.3-.4-2.6-1.5-3.4C37-2.4 24-.2 15 8.8c-9.6 9.6-11.3 23.6-4 31.6L2.7 61.7c-.4 1-.3 2.2.2 3.1.5 1 1.5 1.6 2.5 1.9L31.6 72c.2 1.9.7 3.9 1.3 5.9l-30.1 7c-2 .5-3.3 2.5-2.8 4.5.4 1.7 1.9 2.9 3.6 2.9.3 0 .6 0 .8-.1l31.4-7.3c1.3 2.3 2.7 4.6 4.4 6.9l-23.7 14.8c-1.7 1.1-2.3 3.4-1.2 5.1.7 1.1 1.9 1.8 3.2 1.8.7 0 1.4-.2 2-.6l24.8-15.4c2.5 2.4 5.2 4.6 8.1 6.4l-13.8 13.8c-1.5 1.5-1.5 3.8 0 5.3.7.7 1.7 1.1 2.6 1.1 1 0 1.9-.4 2.6-1.1l15.5-15.6c3.7 1.4 7.6 2.2 11.7 2.3h.6c4.1-.1 8-.9 11.7-2.3L99.8 123c.7.7 1.7 1.1 2.6 1.1 1 0 1.9-.4 2.6-1.1 1.5-1.5 1.5-3.8 0-5.3l-13.8-13.8c2.9-1.8 5.6-4 8.1-6.4l24.8 15.4c.6.4 1.3.6 2 .6 1.2 0 2.5-.6 3.2-1.8 1.1-1.7.6-4.1-1.2-5.1l-23.7-14.8c1.7-2.2 3.2-4.5 4.4-6.9l31.4 7.3c.3.1.6.1.8.1 1.7 0 3.2-1.2 3.6-2.9.8-2.1-.5-4.1-2.5-4.5z'
            style={{
                fill: '#fff',
            }}
        />
    </svg>
)

export default SvgCrustaceans
