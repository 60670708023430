// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .haKRl{display:block;color:var(--font-color);font-weight:bold;font-size:13px}", "",{"version":3,"sources":["webpack://./components/01.atoms/InputLabel/inputlabel.module.scss"],"names":[],"mappings":"AACA,+CACI,aAAA,CACA,uBAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.label {\n    display: block;\n    color: var(--font-color);\n    font-weight: bold;\n    font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"label": "haKRl"
};
export default ___CSS_LOADER_EXPORT___;
