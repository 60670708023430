// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .l5jhK{position:absolute;top:0;bottom:0;left:0;display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;min-height:20vh}#billy-widget#billy-widget#billy-widget .l5jhK .I4EeC{margin:10px 0 0;color:#000;font-size:12px}", "",{"version":3,"sources":["webpack://./components/01.atoms/ActivityMonitor/activitymonitor.module.scss"],"names":[],"mappings":"AACA,+CACI,iBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CAEA,sDACI,eAAA,CACA,UAAA,CACA,cAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.loader {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    min-height: 20vh;\n\n    .loader__text {\n        margin: 10px 0 0;\n        color: #000000;\n        font-size: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"loader": "l5jhK",
	"loader__text": "I4EeC"
};
export default ___CSS_LOADER_EXPORT___;
