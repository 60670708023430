import { FC } from 'react'

const SvgEgg: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 102.7 140'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <path
            d='M51.4 0C23 0 0 47.3 0 84.4 0 121.5 23 140 51.4 140s51.4-18.6 51.4-55.7C102.7 47.3 79.7 0 51.4 0zm0 125.7c-16.6 0-30.1-15.3-30.1-34.1s13.5-34.1 30.1-34.1 30.1 15.3 30.1 34.1-13.5 34.1-30.1 34.1z'
            style={{
                fill: '#fff',
            }}
        />
    </svg>
)

export default SvgEgg
