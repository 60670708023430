import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import { isNullOrEmpty } from '../utils/guards.utils'

// eslint-disable-next-line no-void
void i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath:
                process.env.REACT_APP_ENVIRONMENT === 'local'
                    ? `./locales/{{lng}}.json`
                    : `${process.env.REACT_APP_ASSET_PATH}/locales/{{lng}}.json`,
        },
        cleanCode: true,
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        keySeparator: false,
        load: 'languageOnly',
        lng: !isNullOrEmpty(navigator.languages as Array<string>) ? navigator.languages[0] : navigator.language,
        nonExplicitSupportedLngs: true,
        supportedLngs: ['nl', 'fr', 'en'],
    })

i18n.on('languageChanged', (chosenLanguage) => {
    document.documentElement.setAttribute('lang', chosenLanguage)
})

// eslint-disable-next-line no-restricted-exports
export { default } from 'i18next'
