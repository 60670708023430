import { FC, MouseEventHandler, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { isNullOrEmpty } from '../../../utils/guards.utils'

import style from './button.module.scss'

interface IButtonProperties {
    children: ReactNode
    className?: string
    disabled?: boolean
    external?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    styleType?: 'link' | 'ghost'
    target?: '_blank'
    to?: string
    type?: 'button' | 'submit' | 'link' | 'link-button'
}

const Button: FC<IButtonProperties> = ({
    children,
    className,
    disabled,
    external = false,
    onClick,
    styleType,
    target,
    to,
    type = 'button',
}) => {
    // define classes
    const classes = cx(
        styleType === undefined ? undefined : style[`type-${styleType.toLowerCase()}`],
        style.button,
        isNullOrEmpty(className) ? undefined : className
    )

    if ((type === 'link' && !isNullOrEmpty(to)) || (type === 'link-button' && !isNullOrEmpty(to))) {
        if (external) {
            return (
                <a
                    href={to}
                    style={disabled === true ? { pointerEvents: 'none' } : {}}
                    className={classes}
                    onClick={onClick}
                    target={target}
                    rel='noopener noreferrer'>
                    {children}
                </a>
            )
        }

        return (
            <Link
                to={to}
                style={disabled === true ? { pointerEvents: 'none' } : {}}
                className={classes}
                onClick={onClick}>
                {children}
            </Link>
        )
    }

    return (
        <button
            disabled={disabled}
            type={type === 'submit' ? 'submit' : 'button'}
            className={classes}
            onClick={onClick}>
            {children}
        </button>
    )
}

export default Button
