import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './text.module.scss'

interface ITextProperties {
    children: ReactNode
    size?: 's' | 'm' | 'l' | 'xl' | 'xxl'
    spacing?: '0' | 'xs' | 's' | 'm' | 'l' | 'xl'
    weight?: '200' | '300' | '700' | 'bold'
    align?: 'left' | 'center' | 'right'
    className?: string
}

const Text: FC<ITextProperties> = ({
    children,
    size = 'm',
    spacing = 'm',
    weight = '300',
    align = 'left',
    className,
}) => {
    const classes = classNames(
        styles.text,
        styles[`size--${size}`],
        styles[`spacing--${spacing}`],
        styles[`weight--${weight}`],
        styles[`align--${align}`],
        className
    )

    return <p className={classes}>{children}</p>
}

export default Text
