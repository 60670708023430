import { FC } from 'react'
import classNames from 'classnames'

import { isNotNullOrUndefined } from '../../../utils/guards.utils'
import Currency from '../../01.atoms/Currency/Currency'
import Text from '../../01.atoms/Text/Text'

import style from './pspmethod.module.scss'

interface IPspMethodProperties {
    disabled?: boolean
    active: boolean
    image: string
    label: string
    fee?: string | undefined
    onClick?: () => void
}

const PspMethod: FC<IPspMethodProperties> = ({ disabled = false, active, image, label, onClick, fee }) => (
    <button
        type='button'
        onClick={onClick}
        aria-label={label}
        className={classNames(style.pspMethod, active && style.active, disabled && style.disabled)}>
        <img
            className={style.pspMethod__image}
            src={image}
            alt={label}
        />
        <div className={style.pspMethod__container}>
            <Text
                className={style.pspMethod__label}
                spacing='0'>
                {label}
            </Text>
            {isNotNullOrUndefined(fee) && Number(fee) > 0 ? (
                <div className={style.pspMethod__fee__container}>
                    + <Currency>{fee}</Currency>
                </div>
            ) : null}
        </div>
    </button>
)

export default PspMethod
