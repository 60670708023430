import { FC } from 'react'
import { ErrorMessage, useFormikContext } from 'formik'

import { IFormDataValue } from '../../../config/yup/formData.config'
import IVenuePsp from '../../../structures/IVenuePsp.interface'
import PspType from '../../../structures/PSPType.enum'
import { isNotNullOrUndefined } from '../../../utils/guards.utils'
import InputFeedback from '../../01.atoms/InputFeedback/InputFeedback'
import Psp from '../Psp/Psp'

import styles from './paymentoptions.module.scss'

interface IPaymentOptionsProperties {
    psps: Array<IVenuePsp>
    disabled?: boolean
}

const PaymentOptions: FC<IPaymentOptionsProperties> = ({ psps, disabled = false }) => {
    const formikContext = useFormikContext<IFormDataValue>()

    const onPaymentOptionSelect = (pspType: PspType, methodId: string | null) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        formikContext.setFieldValue('pspType', pspType)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        formikContext.setFieldValue('pspMethod', methodId)
    }

    return (
        <>
            <div className={styles.paymentOptions}>
                {psps.map((psp) => (
                    <Psp
                        key={`${psp.label}_${psp.type}`}
                        type={psp.type}
                        methods={psp.methods}
                        onPaymentOptionSelect={(pspType, methodId = null) => {
                            onPaymentOptionSelect(pspType, methodId)
                        }}
                        disabled={disabled}
                        fee={psp.fee}
                    />
                ))}
            </div>
            {isNotNullOrUndefined(formikContext.errors.pspType) ? (
                <ErrorMessage
                    name='pspType'
                    component={InputFeedback}
                />
            ) : null}
        </>
    )
}

export default PaymentOptions
