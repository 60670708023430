import { FC } from 'react'

const SvgPeanuts: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 109.1 109.8'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <path
            d='m105.6 3.8-.1-.3S97.7-.2 86 0C71.5.3 62.2 13.7 57.1 20.8 46.6 35.5 42.7 40 27.3 45.5S1.6 69.7.5 80.4c-1.9 19.2 2.1 24.6 1.7 26.3v.1c1.7-.4 3.8 5.3 26.5 2 13.5-2 29.4-11.4 34.9-26.8s10-19.3 24.7-29.8c7.1-5.1 20.4-13.3 20.8-28.8.2-11.9-3.5-19.6-3.5-19.6zM54 48.6c3.4-3.8 7-6.7 10-8.9 1.3-1 3.1-.7 4 .5 1 1.3.7 3.1-.5 4-2.8 2.1-6.1 4.8-9.2 8.2-.6.6-1.3 1-2.1 1-.7 0-1.4-.2-1.9-.7-1.2-1.2-1.3-3-.3-4.1zm-28 55c-4 .9-6.8 1.1-6.9 1.1h-.2c-1.7 0-3.1-1.3-3.2-3.1-.1-1.8 1.3-3.3 3-3.4 0 0 2.4-.1 5.8-.9 1.7-.4 3.5.7 3.8 2.4.6 1.7-.5 3.5-2.3 3.9zm-.9-11.9c-.3.1-.5.1-.8.1-1.3 0-2.4-.8-2.8-2.1-.4-1.5.5-3.1 2-3.5.1 0 4.9-1.4 10.1-5.7 1.2-1 3-.8 4 .4 1 1.2.8 3-.4 4-6 5.1-11.9 6.7-12.1 6.8zM51 89.6c-3.2 3.5-7 6.5-11.5 8.9-.5.3-1 .4-1.5.4-1.1 0-2.3-.6-2.8-1.7-.8-1.6-.3-3.5 1.3-4.4 3.8-2.1 7.1-4.6 9.7-7.6 1.2-1.3 3.2-1.4 4.5-.2 1.3 1.3 1.4 3.3.3 4.6zm.5-27.3c-.9 1.7-1.6 3.5-2.3 5.4-.8 2.3-1.8 4.5-3 6.5-.5.9-1.5 1.4-2.5 1.4-.5 0-1-.1-1.4-.4-1.4-.8-1.8-2.5-1.1-3.9 1-1.8 1.9-3.6 2.6-5.6.7-2.1 1.6-4.1 2.6-6 .7-1.4 2.4-2 3.8-1.3 1.4.8 2 2.5 1.3 3.9zm12.8 3.4c-2.3 3.6-4.2 7.1-5.5 10.7-.1.2-.2.5-.3.8-.5 1.3-1.7 2.1-3 2.1-.4 0-.8-.1-1.2-.2-1.7-.6-2.5-2.5-1.8-4.2.1-.2.2-.4.2-.6 1.5-4 3.5-8 6.1-12 1-1.5 3-1.9 4.5-.9 1.5.9 2 2.9 1 4.3zm17.6-19c-3.5 2.9-6.7 5.9-9.6 8.9-.6.7-1.5 1-2.3 1-.8 0-1.6-.3-2.2-.9-1.3-1.2-1.4-3.3-.1-4.5 3-3.2 6.4-6.4 10.2-9.5 1.4-1.1 3.4-.9 4.5.4 1.1 1.4.9 3.5-.5 4.6zm15.9-11.1s-2.1 1.2-5.2 3.3c-.5.4-1.2.5-1.8.5-1 0-2.1-.5-2.7-1.4-1-1.5-.6-3.5.9-4.5 3.4-2.3 5.6-3.5 5.7-3.5 1.6-.9 3.5-.3 4.4 1.2.8 1.6.2 3.5-1.3 4.4z'
            style={{
                fill: '#fff',
            }}
        />
    </svg>
)

export default SvgPeanuts
