import { FC } from 'react'
import { MemoryRouter, Route, Routes } from 'react-router-dom'

import VoucherSelect from '../../components/02.molecules/VoucherSelect/VoucherSelect'
import WidgetContainer from '../../components/02.molecules/WidgetContainer/WidgetContainer'
import WidgetContent from '../../components/02.molecules/WidgetContent/WidgetContent'
import WidgetHeader from '../../components/02.molecules/WidgetHeader/WidgetHeader'
import Credentials from '../../components/03.organisms/Credentials/UserCredentials'
import PurchaseStatus from '../../components/03.organisms/PurchaseStatus/PurchaseStatus'
import RoutePaths from '../../structures/RoutePaths.enum'

const VouchersWidget: FC<Record<string, never>> = () => (
    <MemoryRouter initialEntries={[RoutePaths.VOUCHER_SELECT]}>
        <WidgetContainer>
            <WidgetHeader />

            <WidgetContent>
                <Routes>
                    <Route
                        path={RoutePaths.VOUCHER_SELECT}
                        element={<VoucherSelect />}
                    />
                    <Route
                        path={RoutePaths.CREDENTIALS}
                        element={<Credentials />}
                    />
                    <Route
                        path={RoutePaths.PURCHASE_STATUS}
                        element={<PurchaseStatus />}
                    />
                </Routes>
            </WidgetContent>
        </WidgetContainer>
    </MemoryRouter>
)

export default VouchersWidget
