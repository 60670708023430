import { FC, ReactNode } from 'react'

import IConfig from '../structures/IConfig.interface'

import { ConfigProvider } from './ConfigContext'
import { EventProvider } from './EventContext'
import { FormValuesProvider } from './FormValuesContext'
import { GlobalsProvider } from './GlobalsContext'
import { VenueProvider } from './VenueContext'
import { VouchersProvider } from './VouchersContext'

interface IApplicationContextProperties {
    children: ReactNode
    config: IConfig
}

const ApplicationContext: FC<IApplicationContextProperties> = ({ children, config }) => (
    <ConfigProvider userConfig={config}>
        <VenueProvider>
            <EventProvider>
                <FormValuesProvider>
                    <VouchersProvider>
                        <GlobalsProvider>{children}</GlobalsProvider>
                    </VouchersProvider>
                </FormValuesProvider>
            </EventProvider>
        </VenueProvider>
    </ConfigProvider>
)

export default ApplicationContext
