import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import style from './widgetintro.module.scss'

interface IWidgetIntroProperties {
    children: ReactNode
    className?: string
}

const WidgetIntro: FC<IWidgetIntroProperties> = ({ children, className }) => (
    <section className={classNames(style.widgetIntro, className)}>{children}</section>
)

export default WidgetIntro
