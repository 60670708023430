import { FC, MouseEventHandler } from 'react'
import { FaAngleLeft } from '@react-icons/all-files/fa/FaAngleLeft'

import style from './backbutton.module.scss'

interface IBackButtonProperties {
    onClick?: MouseEventHandler<HTMLButtonElement>
}

const BackButton: FC<IBackButtonProperties> = ({ onClick }) => (
    <button
        type='button'
        className={style.button}
        onClick={onClick}
        aria-label='back'>
        <FaAngleLeft />
    </button>
)

export default BackButton
