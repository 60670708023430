enum PspType {
    PAYCONIQ = 'payconiq',
    MOLLIE = 'mollie',
    MOLLIECONNECT = 'mollieconnect',
    MULTISAFEPAY = 'multisafepay',
    ADYEN = 'adyen',
    PAYBE = 'paybe',
    COD = 'cod',
    POS = 'pos',
}

export default PspType
