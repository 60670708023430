// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#billy-widget#billy-widget#billy-widget .QlhIN{padding:10px 0}#billy-widget#billy-widget#billy-widget .QlhIN:last-child{padding-bottom:0}", "",{"version":3,"sources":["webpack://./components/01.atoms/FormElement/formelement.module.scss"],"names":[],"mappings":"AACA,+CACI,cAAA,CAEA,0DACI,gBAAA","sourcesContent":["@use \"src/components/00.fundament/envConditionalStyles_production.scss\" as *;\n.formElement {\n    padding: 10px 0;\n\n    &:last-child {\n        padding-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billy-widget": "billy-widget",
	"formElement": "QlhIN"
};
export default ___CSS_LOADER_EXPORT___;
