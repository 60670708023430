type TypedEntries<T> = Array<
    {
        [K in keyof T]: [K, T[K]]
    }[keyof T]
>

const getTypedEntries = <T extends object>(object: T): TypedEntries<T> =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.entries(object) as any

export default getTypedEntries
