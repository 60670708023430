import { FC, ReactNode } from 'react'

import { EVENT_BY_ID } from '../api/endpoints.api'
import useFetch from '../api/useFetch'
import IEvent from '../structures/IEvent.interface'
import IFetchResult from '../structures/IFetchResult.interface'
import createGenericContext from '../utils/genericContext.utils'

import { useConfigContext } from './ConfigContext'

const [useEventContext, EventFetchContextProvider] = createGenericContext<IFetchResult<IEvent>>()

interface IEventProviderProperties {
    children: ReactNode
}

const EventProvider: FC<IEventProviderProperties> = ({ children }) => {
    const config = useConfigContext()
    const eventFetchResult = useFetch<IEvent>(EVENT_BY_ID(config.menu))

    return <EventFetchContextProvider value={eventFetchResult}>{children}</EventFetchContextProvider>
}

export { EventProvider, useEventContext }
