import { FC } from 'react'
import classNames from 'classnames'
import { FieldProps } from 'formik'

import useMemoTranslation from '../../../hooks/useMemoTranslation'
import { isNullOrEmpty } from '../../../utils/guards.utils'
import SelectBoxOption from '../SelectBoxOption/SelectBoxOption'

import style from './selectBox.module.scss'

interface ISelectBoxProperties {
    id: string
    disabled: boolean
    options?: Array<string> // optional because we can't ensure it gets passed through in formik component.
}

const SelectBox: FC<FieldProps & ISelectBoxProperties> = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched },
    id,
    disabled = false,
    options,
}) => {
    const { t } = useMemoTranslation()
    const classes = classNames(style.selectBox, {
        'has-success': !isNullOrEmpty(value) || (errors[name] === undefined && touched[name] === true), // handle prefilled or user-filled
        'has-error': errors[name] !== undefined && touched[name] === true,
        'u-disabled': disabled,
    })

    return (
        <div className={classes}>
            <select
                disabled={disabled}
                name={name}
                id={id}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}>
                <SelectBoxOption
                    value=''
                    label={t('input.select.placeholder')}
                />
                {options?.map((option) => (
                    <SelectBoxOption
                        key={option}
                        value={option}
                        label={option}
                    />
                ))}
            </select>
        </div>
    )
}

export default SelectBox
