import { FC } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import classNames from 'classnames'

import { isNullOrEmpty } from '../../../utils/guards.utils'

import styles from './activitymonitor.module.scss'

interface IActivityMonitorProperties {
    height?: number
    isStatic?: boolean
    style?: Record<string, string>
    text?: string
    className?: string
}

const ActivityMonitor: FC<IActivityMonitorProperties> = ({ height, isStatic, style, text, className }) => {
    const styleExtension: Record<string, string> = {
        ...(isStatic === true ? { position: 'static' } : undefined),
        ...(height === undefined
            ? undefined
            : {
                  height: `${height}px`,
                  minHeight: `${height}px`,
              }),
        ...style,
    }

    return (
        <div
            className={classNames(styles.loader, className)}
            style={styleExtension}>
            <BeatLoader color='var(--theme-color)' />
            {!isNullOrEmpty(text) && <p className={styles.loader__text}>{text}</p>}
        </div>
    )
}

export default ActivityMonitor
