import { FC } from 'react'
import { MemoryRouter } from 'react-router-dom'

import Heading from '../../components/01.atoms/Heading/Heading'
import Text from '../../components/01.atoms/Text/Text'
import Menu from '../../components/02.molecules/Menu/Menu'
import WidgetContainer from '../../components/02.molecules/WidgetContainer/WidgetContainer'
import WidgetContent from '../../components/02.molecules/WidgetContent/WidgetContent'
import WidgetHeader from '../../components/02.molecules/WidgetHeader/WidgetHeader'
import WidgetIntro from '../../components/02.molecules/WidgetIntro/WidgetIntro'
import { useEventContext } from '../../context/EventContext'
import RoutePaths from '../../structures/RoutePaths.enum'
import { isNullOrEmpty } from '../../utils/guards.utils'

const MenuWidget: FC<Record<string, never>> = () => {
    const { data: event } = useEventContext()

    return (
        <MemoryRouter initialEntries={[RoutePaths.ROOT]}>
            <WidgetContainer>
                <WidgetHeader />

                <WidgetContent>
                    <WidgetIntro>
                        <Heading
                            element='h1'
                            align='center'
                            spacing={isNullOrEmpty(event?.description) ? 'm' : '0'}>
                            {isNullOrEmpty(event?.name) ? '' : event?.name}
                        </Heading>
                        {isNullOrEmpty(event?.description) && <Text align='center'>{event?.description}</Text>}
                    </WidgetIntro>
                    <Menu />
                </WidgetContent>
            </WidgetContainer>
        </MemoryRouter>
    )
}

export default MenuWidget
