import { FC } from 'react'

const SvgMustard: FC<Record<string, never>> = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 67 154.5'
        style={{
            overflow: 'visible',
        }}
        xmlSpace='preserve'>
        <style>{'.mustard_svg__st0{fill:#fff}'}</style>
        <path
            className='mustard_svg__st0'
            d='M61.2 42.5H5.8C2.6 42.5 0 45 0 48.2v100.5c0 3.2 2.6 5.8 5.8 5.8h55.4c3.2 0 5.8-2.6 5.8-5.8V48.2c0-3.2-2.6-5.7-5.8-5.7zm-5.8 56H11.6V54h43.9v44.5zM13.9 35.2h39.3c2.4 0 4.3-1.9 4.3-4.3v-9.8c0-2.4-1.9-4.3-4.3-4.3h-9.8L40 3.3C39.5 1.4 37.8 0 35.8 0H30c-2 0-3.8 1.4-4.2 3.4l-3 13.4h-9c-2.4 0-4.3 1.9-4.3 4.3v9.8c0 2.4 2 4.3 4.4 4.3z'
        />
    </svg>
)

export default SvgMustard
